import './alterPassword.css';
import React, { ChangeEvent, MouseEvent, useState } from 'react';
import MessageCancel from '../../components/CardMessage/messageCancel';
import MessageRequest from '../../components/CardMessage/MessageRequest/messageRequest';
import Navbar from '../../components/Navbar/navbar';
import EditProfile from '../PersonalData/EditProfile/editProfile';
import { alterPassword } from '../../api/resetPassword';
import { registerUserLogs } from '../../api/userLogs';
import ColProfile from '../../components/ColProfile/colProfile';
import Drawer from '@mui/material/Drawer';

export default function AlterPassword() {
    const [inputCurrentPassword, setinputCurrentPassword] = useState<string>('');
    const [inputPassword, setinputPassword] = useState<string>('');
    const [inputConfPassword, setinputConfPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<string | null>(null);
    const [errorCurrentPassword, setErrorCurrentPassword] = useState<string | null>(null);
    const [errorConfPassword, setErrorConfPassword] = useState<string | null>(null);
    const [activeMessage, setActiveMessage] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<boolean>(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const list_page = window.location.href.split('/')
    const page = list_page[list_page.length - 1]
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const togglePasswordVisibility = () => {
        setShowCurrentPassword((prevShowPassword) => !prevShowPassword);
        setShowPassword((prevShowCurrentPassword) => !prevShowCurrentPassword);
        setShowConfPassword((prevShowConfPassword) => !prevShowConfPassword);
    };
    
    const handleInputPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputPassword(event.target.value);
        setErrorPassword(null);
    }

    const handleInputCurrentPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCurrentPassword(event.target.value);
        setErrorCurrentPassword(null);
    }

    const handleInputConfPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputConfPassword(event.target.value);
        setErrorConfPassword(null);
    }

    const clearInputsStage = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        setinputPassword('');
        setinputCurrentPassword('');
        setinputConfPassword('');
    }

    const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputCurrentPassword.trim() === '') {
            setErrorCurrentPassword('Campo obrigatório.')
            return;
        } else if (inputCurrentPassword.length < 8) {
            setErrorCurrentPassword('Senha contém menos de 8 caracteres.')  
        } else {
            setErrorCurrentPassword('')
        }

        if (inputPassword.trim() === '') {
            setErrorPassword('Campo obrigatório.')
            return;
        } else if (inputPassword.length < 8) {
            setErrorPassword('Senha contém menos de 8 caracteres.')  
        } else {
            setErrorPassword('')
        }

        if (inputConfPassword.trim() === '') {
            setErrorPassword('Campo obrigatório.')
            return;
        } else if (inputConfPassword !== inputPassword) {
            setErrorConfPassword('Senhas não correspondem.')
            return;
        } else {
            setErrorConfPassword('')
        }

        const resetPassword: any = await alterPassword(inputCurrentPassword, inputPassword);

        if (resetPassword.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Senha alterada com sucesso.');
            registerUserLogs("Trocou sua senha.");
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique seus dados e tente novamente.')
        }
    }

    const classNamePassword = errorPassword ? 'input-register-error' : 'input-register';
    const classNameCurrentPassword = errorCurrentPassword ? 'input-register-error' : 'input-register';
    const classNameConfPassword = errorConfPassword ? 'input-register-error' : 'input-register';
    const [activeEdit, setActiveEdit] = useState(false);

    const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveMessage(!activeMessage);
        setReloadMessage(false)
    }

    const handleActiveEdit = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setActiveEdit(true);
    }

    return (
        <div className='register-col'>
            <Navbar title="Sistema AMZ" user={false} menu={toggleDrawer(true)} />
            <section>
            <div className='container-sidebar'>
                    <div className="sidebar-personal-data">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'dados-pessoais' && !activeEdit ? 'selected' : ''} href="dados-pessoais">Dados Pessoais</a>
                            <a className={activeEdit ? 'selected' : ''} onClick={handleActiveEdit}>Editar Dados</a>
                            <a className={page === 'alterar-senha' ? 'selected' : ''} href='alterar-senha'>Alterar Senha</a>
                            <a className={page === 'relatar-problema' && !activeEdit ? 'selected' : ''} href='/relatar-problema'>Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </div>
                <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%", width: "250px", overflowY: "hidden!important"}}>
                    <div className="sidebar-home">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'dados-pessoais' && !activeEdit ? 'selected' : ''} href="dados-pessoais">Dados Pessoais</a>
                            <a className={activeEdit ? 'selected' : ''} onClick={handleActiveEdit}>Editar Dados</a>
                            <a className={page === 'alterar-senha' ? 'selected' : ''} href='alterar-senha'>Alterar Senha</a>
                            <a className={page === 'relatar-problema' && !activeEdit ? 'selected' : ''} href='/relatar-problema'>Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </Drawer>
                <section className='section-register'>
                    <EditProfile activeProps={activeEdit} url='/alterar-senha' />
                    <MessageCancel activeProps={activeMessage} reloadProps={false} url='/pagina-inicial' />
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                    <div className="register-box">
                        <div className='row-1'>
                            <div className='col-title-sub-title'>
                                <div className='col-title-image'>
                                    <img
                                        src="/amz.png"
                                        alt="Imagem de cadastro"
                                        className="register-image"
                                    />
                                    <h2>ALTERAR SENHA</h2>
                                </div>
                                <p className='p-message'>
                                    *sua senha deve conter no mínimo 8 caracteres. 
                                </p>
                            </div>
                        </div>
                        <form action="" style={{paddingLeft: '40px'}}>
                            <div className='row-childs' style={{paddingLeft: '120px', paddingTop: '30px', paddingBottom: '40px'}}>
                                <div className='col-childs1'>
                                    <div className='row-label'>
                                        <label className='label-register'>Digite a senha atual</label>
                                        <p className='required'>*</p>
                                    </div>
                                    <input type={showCurrentPassword ? 'text' : 'password'} id='password' onChange={handleInputCurrentPassword} className={classNameCurrentPassword} value={inputCurrentPassword} />
                                    <span>{errorCurrentPassword}</span>
                                    <div className='row-label'>
                                        <label className='label-register'>Nova senha</label>
                                        <p className='required'>*</p>
                                    </div>
                                    <input type={showPassword ? 'text' : 'password'} id='password' onChange={handleInputPassword} className={classNamePassword} value={inputPassword} />
                                    <span>{errorPassword}</span>
                                    <div className='row-label'>
                                        <label className='label-register'>Confirmar senha</label>
                                        <p className='required'>*</p>
                                    </div>
                                    <input type={showConfPassword ? 'text' : 'password'} id='conf-password' onChange={handleInputConfPassword} className={classNameConfPassword} value={inputConfPassword} />
                                    <span>{errorConfPassword}</span>
                                    <div className="form-checkbox" id="form_checkbox">
                                        <label className='label-register'>
                                            <input type="checkbox" id="view_password" onChange={togglePasswordVisibility}  /> Mostrar senhas
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row-buttons'>
                                <button className='button-register' onClick={handleSubmit} style={{'marginRight': '20px'}}>Confirmar</button>
                                <a onClick={clearInputsStage} style={{'cursor': 'pointer'}}><b><u>Limpar</u></b></a>
                            </div>
                        </form>
                    </div>
                </section>
            </section>
        </div>
    )
}