import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import './dashboard.css';  // Importando o arquivo CSS
import ColProfile from '../../components/ColProfile/colProfile';
import Loading from '../../components/Loading/loading';
import Navbar from '../../components/Navbar/navbar';
import { resultAudit } from '../../api/results';
import { useParams } from "react-router-dom";
import { BarChart } from '@mui/x-charts/BarChart';
import { registerUserLogs } from '../../api/userLogs';
import Drawer from '@mui/material/Drawer';

const Dashboard = () => {
    const { id } = useParams();
    const list_stage = window.location.href.split('/')
    const stage = list_stage[list_stage.length - 2]
    const [activeLoading, setActiveLoading] = useState(true)
    const [dataResult, setDataResult] = useState<any>([]);
    const [resultsAccuracy, setResultsAccuracy] = useState<any>([]);
    const [dataSpreadsheets, setDataSpreadsheets] = useState<any>([]);
    const [dataComparative, setDataComparative] = useState([{melhor: 0, pior: 0, etapa: ''}]);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            const responseResult: any = await resultAudit(id || '');
            setDataResult(responseResult.data);

            if(responseResult.status === 200) {
                setActiveLoading(false);
                registerUserLogs("Visualizou resultado.");
            }
        }

        fetchData();
    }, [])

    const titleCertify = [
        'Não Certifica',
        'Certifica com Pendência (s)',
        'Certifica sem Pendência (s)'
    ]

    useEffect(() => {
        setResultsAccuracy([dataResult.not_certify, dataResult.certifies_pending, dataResult.certifies_without_pending]);
        setDataSpreadsheets(dataResult.data_spreadsheet);
        if (dataResult.comparative) {
            const formattedComparativeData = dataResult.comparative; 
            const list = [];

            for(let data of formattedComparativeData) {
                list.push(data)
            }
            setDataComparative(list);
        } 
    }, [dataResult])
    
    const valueFormatter = (value: number) => String(value);

    return (
        <div className='home'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Sistema AMZ" user={true} search={true} />
            <div className='container-sidebar-register'>
                <div className="sidebar">
                    {localStorage.getItem('profile') === 'administrador' && (
                        <div className="menu_stages">
                            <a className={stage === 'pagina-inicial' ? 'selected' : ''} href="/pagina-inicial">Página Inicial</a>
                            <a  href="/dados-pessoais">Dados Pessoais</a>
                            <a className={stage === 'planilhas' ? 'selected' : ''} href="/planilhas">Auditoria</a>
                            <a className={stage === 'etapas' ? 'selected' : ''} href="/etapas">Etapas</a>
                            <a className={stage === 'relatorios' ? 'selected' : ''} href="/relatorios">Relatórios</a>
                            <a className={stage === 'resultados' ? 'selected' : ''} href="/resultados">Resultados </a>
                            <a className={stage === 'simulacao' ? 'selected' : ''} href="/simulacao">Simulação</a>
                            <a className={stage === 'usuarios' ? 'selected' : ''} href="/usuarios">Usuários</a>
                        </div>
                    )}
                    {localStorage.getItem('profile') === 'auditor' && (
                        <div className="menu_stages">
                            <a className={stage === 'pagina-inicial' ? 'selected' : ''} href="/pagina-inicial">Página Inicial</a>
                            <a  href="/dados-pessoais">Dados Pessoais</a>
                            <a className={stage === 'planilhas' ? 'selected' : ''} href="/planilhas">Auditoria</a>
                            <a className={stage === 'etapas' ? 'selected' : ''} href="/etapas">Etapas</a>
                            <a className={stage === 'relatorios' ? 'selected' : ''} href="/relatorios">Relatórios</a>
                            <a className={stage === 'resultados' ? 'selected' : ''} href="/resultados">Resultados </a>
                        </div>
                    )}
                    {localStorage.getItem('profile') === 'camara' && (
                        <div className="menu_stages">
                            <a className={stage === 'pagina-inicial' ? 'selected' : ''} href="/pagina-inicial">Página Inicial</a>
                            <a  href="/dados-pessoais">Dados Pessoais</a>
                            <a className={stage === 'planilhas' ? 'selected' : ''} href="/planilhas">Auditoria</a>
                            <a className={stage === 'relatorios' ? 'selected' : ''} href="/relatorios">Relatórios</a>
                            <a className={stage === 'resultados' ? 'selected' : ''} href="/resultados">Resultados </a>
                        </div>
                    )}
                    <ColProfile />
                </div>
            </div>
            <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                <div className="sidebar">
                    {localStorage.getItem('profile') === 'administrador' && (
                        <div className="menu_stages">
                            <a className={stage === 'pagina-inicial' ? 'selected' : ''} href="/pagina-inicial">Página Inicial</a>
                            <a  href="/dados-pessoais">Dados Pessoais</a>
                            <a className={stage === 'planilhas' ? 'selected' : ''} href="/planilhas">Auditoria</a>
                            <a className={stage === 'etapas' ? 'selected' : ''} href="/etapas">Etapas</a>
                            <a className={stage === 'relatorios' ? 'selected' : ''} href="/relatorios">Relatórios</a>
                            <a className={stage === 'resultados' ? 'selected' : ''} href="/resultados">Resultados </a>
                            <a className={stage === 'simulacao' ? 'selected' : ''} href="/simulacao">Simulação</a>
                            <a className={stage === 'usuarios' ? 'selected' : ''} href="/usuarios">Usuários</a>
                        </div>
                    )}
                    {localStorage.getItem('profile') === 'auditor' && (
                        <div className="menu_stages">
                            <a className={stage === 'pagina-inicial' ? 'selected' : ''} href="/pagina-inicial">Página Inicial</a>
                            <a  href="/dados-pessoais">Dados Pessoais</a>
                            <a className={stage === 'planilhas' ? 'selected' : ''} href="/planilhas">Auditoria</a>
                            <a className={stage === 'etapas' ? 'selected' : ''} href="/etapas">Etapas</a>
                            <a className={stage === 'relatorios' ? 'selected' : ''} href="/relatorios">Relatórios</a>
                            <a className={stage === 'resultados' ? 'selected' : ''} href="/resultados">Resultados </a>
                        </div>
                    )}
                    {localStorage.getItem('profile') === 'camara' && (
                        <div className="menu_stages">
                            <a className={stage === 'pagina-inicial' ? 'selected' : ''} href="/pagina-inicial">Página Inicial</a>
                            <a  href="/dados-pessoais">Dados Pessoais</a>
                            <a className={stage === 'planilhas' ? 'selected' : ''} href="/planilhas">Auditoria</a>
                            <a className={stage === 'relatorios' ? 'selected' : ''} href="/relatorios">Relatórios</a>
                            <a className={stage === 'resultados' ? 'selected' : ''} href="/resultados">Resultados </a>
                        </div>
                    )}
                    <ColProfile />
                </div>
            </Drawer>
            <section className='section-home'>
                <div style={{ width: "80%" }}>
                    <div className='col-title-image-view-stages'>
                        <img
                            src="/amz.png"
                            alt="Imagem de cadastro"
                            className="register-image"
                        />
                        <h2>Resultados</h2>
                        <hr className='divider-title' style={{ 'width': '90%' }} />
                    </div>
                    {/* <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <p className='p-result'>Resultado: Certifica sem Pendências</p>
                    </Row> */}
                    <h3 className='h3-result'>Feedback Geral</h3>
                    {/* separador */}
                    <hr className="Separator" /> 
                    <div className="NewLine"></div>
                    <div className='graphic'>
                        {dataComparative && <BarChart
                            dataset={dataComparative.map((item: any) => ({
                                melhor: item.min,
                                pior: item.max,
                                etapa: item.stage
                            }))}
                            xAxis={[{ scaleType: 'band', data: dataComparative.map((item: any) => item.stage) }]}
                            series={[
                                { dataKey: 'melhor', label: 'Ponto forte', valueFormatter, color: '#26BD2C' },
                                { dataKey: 'pior', label: 'Ponto fraco', valueFormatter, color: '#ED3232' },
                            ]}
                            width={500}
                            height={300}
                        />}
                    </div>
                    <h3 className='h3-result'> Análise de Resultados</h3>
                    {/* separador */}
                    <hr className="Separator" /> 
                    <Card className="Dashboard">
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Resultado</th>
                                        <th>COD</th>
                                        <th>Indicadores</th>
                                        <th>Grau Crítico</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataSpreadsheets && dataSpreadsheets.map((spreadsheet: any, index: number) => (
                                        <tr key={index}>
                                        <td>{spreadsheet.result}</td>
                                        <td>{spreadsheet.indicators_id_cod}</td>
                                        <td>{spreadsheet.indicators_id_indicators}</td>
                                        <td>{spreadsheet.critical_degree}</td>
                                        </tr>
                                    ))}
                                </tbody>            
                            </Table>
                        </Card.Body>
                    </Card>
                    <Card className="Dashboard">
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>RESULTADO</th>
                                        <th>Contagem de COD</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resultsAccuracy.map((r: any, index: number) => (
                                        <tr key={index}>
                                            <td>{titleCertify[index]}</td>
                                            <td>{r && r.qtd_cod || 0}</td>
                                            <td>{r && r.percentage || 0}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </section>
        </div>
    );
}

export default Dashboard;