import React, { useRef, useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import './fileUploadArea.css';
import axios from 'axios';
import Loading from '../Loading/loading';

type FileUploadAreaProps = {
  onFileSelect: any,
  nameFile: string,
  update: boolean,
  idUser?: string | null,
  idStage: number,
  response: any,
}

const FilesUploadArea = ({ onFileSelect, nameFile, update, idUser, idStage, response }: FileUploadAreaProps) => {
  const dropAreaRef = useRef<any>(null);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false)

  const handleDragOver = (event: any) => {
    event.preventDefault();
    dropAreaRef.current.classList.add('drag-over');
  };

  const handleDragLeave = () => {
    dropAreaRef.current.classList.remove('drag-over');
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    dropAreaRef.current.classList.remove('drag-over');

    const files = Array.from(event.dataTransfer.files);
    if (files.length > 0) {
      onFileSelect(files);
      setAttachedFiles((prevFiles): any => [...prevFiles, ...files]);
    }
  };

  const handleFileInputChange = (event: ChangeEvent<any>) => {
    const files = Array.from(event.target.files);
    if (files.length > 0 ) {
      onFileSelect(files);
      setAttachedFiles((prevFiles): any => [...prevFiles, ...files]);
    }
  };

  const handleViewFile = (file: any) => {
      window.open(URL.createObjectURL(file), '_blank');
  }

  const handleRemoveFile = (index: number) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setSelectedFileIndex(null)
  }

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); 
    const inputUpload: any = document.getElementById('input-upload');
    inputUpload.click();
  };

  const handleSubmitImages = async () => {
    setActiveLoading(true);
    let listImages = [];
    const formData = new FormData();
    formData.append('user_id', idUser ? idUser : '');
    formData.append('stage_id', String(idStage));
    
    attachedFiles.forEach((file, index) => {
      listImages.push(file);
      formData.append(`images[${index}]`, file);
    });

    const responseDoc: any = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/register-documentation/', 
      formData,
      {
          headers: {
              "Authorization": "Bearer " + localStorage.getItem("access_token")
          }
      })
    
    if (responseDoc.status === 201) {
      response(true);
    } else {
      response(false);
    }
    setActiveLoading(false);

  };

  useEffect(() => {
    if (!update) {
      setAttachedFiles([]);
      setSelectedFileIndex(null);
    } else {
      setAttachedFiles(attachedFiles);
    }
    
  }, [update]);

  return (
    <div className="row-spreadsheet" style={{ width: '71.5%' }}>
      <div className="area-loading-upload">
        <Loading activeProps={activeLoading}/>
      </div>
      <div className="col-spreadsheet" style={{ marginRight: '90px' }}>
        <label className='label-spreadsheet'>Anexos:</label>
        <div
          className="files-upload-area"
          ref={dropAreaRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{ marginTop: '10px' }}
        >
          <p className='p-area-upload'>{!nameFile ? 'arraste aqui o arquivo' : nameFile}</p>
          <input
            id="input-upload"
            className="input-upload"
            type="file"
            onChange={handleFileInputChange}
            multiple  
          />
        </div>
        <div className='row-button-spreadsheet' style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <button className='button_add--spreadsheet' onClick={handleButtonClick}>
            adicionar
          </button>
          <button className='button_add--spreadsheet' onClick={handleSubmitImages}>
            salvar
          </button>
        </div>
      </div>
      <div className="col-spreadsheet">
        <div className="attached-files-list" style={{ marginTop: '28px' }}>
          <ul className='ul-files'>
            {attachedFiles.map((file: any, index: number) => (
              <li className='li-file' key={index}>
                {file.name}
                <div className='buttons-file'>
                  <button className='button-view-file'>
                      <img src="/remove.svg" alt="svg_remove" className='img-icon-file' onClick={() => handleRemoveFile(index)}/>
                  </button>
                  <button className='button-view-file'>
                      <img src="/olho_negro.svg" alt="olho_negro" className='img-icon-view-file' onClick={() => handleViewFile(file)} />
                  </button>
                </div>  
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilesUploadArea;
