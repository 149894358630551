import '../personalData.css'
import Navbar from '../../../components/Navbar/navbar';
import ColProfile from '../../../components/ColProfile/colProfile';
import { profile } from '../../../api/profile';
import React, { useState, useEffect } from 'react';
import Loading from '../../../components/Loading/loading';
import MessageRequest from '../../../components/CardMessage/MessageRequest/messageRequest';
import AdminPersonalData from './adminPersonalData';
import { registerUserLogs } from '../../../api/userLogs';
import Drawer from '@mui/material/Drawer';

export default function AdminViewUsers() {
    const [profiles, setProfiles] = useState([]);
    const [profileSelected, setProfileSelected] = useState([]);
    const list_page = window.location.href.split('/')
    const page = list_page[list_page.length - 1]
    const [activeMessageRequest, setActiveMessageRequest] = useState(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState('');
    const [messageRequest, setMessageRequest] = useState('');
    const [screenUser, setScreenUser] = useState(1)
    const [activeLoading, setActiveLoading] = useState(true)
    const [searchData, setSearchData] = useState('');
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileRequest = await profile('admin-amz');
                setProfiles(profileRequest);
                setActiveLoading(false);
                registerUserLogs("Visualizou lista de usuários.");
            } catch (error) {
                console.error('Erro ao buscar perfil:', error);
            }
        };

        fetchData();
    }, []);

    const handleNextScreen = (seleted: any) => {
        setScreenUser(2);
        setProfileSelected(seleted);
    }

    const handleSearch = (event: any) => {
        setSearchData(event.target.value)
    }
    
    const profilesFiltred = profiles.filter((project: any) => project.name.toLowerCase().startsWith(searchData.toLowerCase()));

    return (
        <div className='personal-data'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Sistema AMZ" user={true} search={true} onChangeSearch={handleSearch} menu={toggleDrawer(true)}/>
            <section>
                <div className='container-sidebar-register'>
                    <div className="sidebar-personal-data">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'usuarios' ? 'selected' : ''} href="usuarios">Usuários</a>
                            <a className={page === 'cadastrar-usuario' ? 'selected' : ''} href="cadastrar-usuario">Cadastrar Usuário</a>
                            <a className={page === 'cadastrar-usuario' ? 'selected' : ''} href="/relatar-problema">Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </div>
                <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                    <div className="sidebar-home">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'usuarios' ? 'selected' : ''} href="usuarios">Usuários</a>
                            <a className={page === 'cadastrar-usuario' ? 'selected' : ''} href="cadastrar-usuario">Cadastrar Usuário</a>
                            <a className={page === 'cadastrar-usuario' ? 'selected' : ''} href="/relatar-problema">Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </Drawer>
                {screenUser === 1 ?
                    (<section className='section-profile'>
                        <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest}/>
                        <div className='row-users-admin' style={{'paddingBottom': 0}}>
                            <h3 className='title-user'>
                                Usuário
                            </h3>
                            <h3>
                                E-mail
                            </h3>
                            <h3 className='title-profile-admin'>
                                Perfil
                            </h3>
                            <h3>
                                Visualizar
                            </h3>
                        </div>
                        <hr className='divider-profile' style={{'marginBottom': 0}} />
                        {profilesFiltred.map((profile: any, index) => (
                            <div className='col-users'>
                                <div className='row-users-admin' style={{'height': '40px'}}>
                                    <p key={index} className='user-name'>
                                        {profile.name}
                                    </p>
                                    <p key={index} className='user-name'>
                                        {profile.email}
                                    </p>
                                    <p key={index}>
                                        {profile.groups}
                                    </p>
                                    <button>
                                        <img src="./olho_negro.svg" alt="olho_negro" className='img-icon-view' onClick={() => handleNextScreen(profile)} />
                                    </button>
                                </div>
                                <hr className='divider-profile' style={{'marginBottom': 0}} />
                            </div>
                        ))}
                    </section>) :
                    <AdminPersonalData request={profileSelected} />
                }
            </section>
        </div>
    );
}