import React, { useState, useEffect } from 'react';
import './home.css'
import Navbar from '../../components/Navbar/navbar' 
import MessageRequest from '../../components/CardMessage/MessageRequest/messageRequest';
import Sidebar from '../../components/Sidebar/sidebar';
import { profile } from '../../api/profile';
import Loading from '../../components/Loading/loading';
import Drawer from '@mui/material/Drawer';

export default function StagesList() {
    const [activeMessageRequest, setActiveMessageRequest] = useState(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState('');
    const [messageRequest, setMessageRequest] = useState('');
    const [nameProfile, setNameProfile] = useState('');
    const [groupProfile, setGroupProfile] = useState('');
    const [activeLoading, setActiveLoading] = useState(true)
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileRequest = await profile('profile');
                setNameProfile(profileRequest['cliente']['name']);
                setGroupProfile(profileRequest['perfil']);
                if (nameProfile !== null || nameProfile !== '') {
                    setActiveLoading(false)
                }
            } catch (error) {
                console.error('Erro ao buscar perfil:', error);
            }
        };

        fetchData();
    }, []);

    const list_page = window.location.href.split('/')
    const page = list_page[list_page.length - 1]

    return (
        <div className='home'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Sistema AMZ" user={true} search={true} menu={toggleDrawer(true)} />
            <div className='container-sidebar'>
                <Sidebar page={page} groupProfile={groupProfile} />
            </div>
            <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%", width: "250px", overflowY: "hidden!important"}}>
                {<Sidebar page={page} groupProfile={groupProfile} />}
            </Drawer>
            <section className='section-home'>
                <div className={'col-title-image'} style={{ paddingLeft: 0 }}>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image"
                    />
                    <h2 className='name-profile-home'>Olá, {nameProfile}</h2>
                </div>
                <div className='background-img-amz'>
                    <img src="/background.png" alt="" />
                </div>
                <div className='col-text-home'>
                    <div className='row-title-home'>
                        <h2 className='title-amz-home'>AMZ é inovação</h2>
                        <p className='subtitle-amz-home'>A única certificação no Brasil que possibilita a certificação por Etapas​</p>
                    </div>
                    <p className='p-text-home'>
                        O AMZ, Sistema de Certificação Amazon, se destaca como o pioneiro e exclusivo Instituto de certificação nascido na região amazônica. Ele emprega métodos e protocolos inovadores no contexto da certificação ambiental de sistemas, produtos e/ou produtos autênticos da Amazônia, abrangendo a vasta área da Amazônia Legal. O processo de certificação de A a Z é bastante simples. O empreendimento segue um sistema de certificação por etapas, onde a empresa pode optar por certificar apenas as etapas nas quais tem confiança em estar em conformidade com os padrões da Certificação AMZ. Cada etapa certificada é auditada pelo AMZ para verificar a conformidade. Se tudo estiver correto, a empresa pode avançar para as próximas etapas da certificação. Simultaneamente, durante a certificação de uma etapa, a empresa pode se preparar para as próximas etapas. Ao completar todas as etapas com sucesso, a empresa se torna elegível para receber o Selo AMZ.
                    </p>
                    <div className='col-services-amz' style={{ marginTop: "130px" }}>
                        <div className='row-title-home' style={{ marginTop: "30px" }}>
                            <img className='img-services-amz' src="/servico1.png" alt="servico1" style={{ marginRight: "30px" }} />
                            <img className='img-services-amz' src="/servicos.png" alt="servicos" />
                        </div>
                        <div className='row-title-home'>
                            <img className='img-services-amz' src="/carbon.png" alt="carbono" style={{ marginTop: "20px" }} />
                        </div>
                    </div>
                </div>
            </section>
            <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
        </div>
    );
}