import React from 'react';
import './bar.css'

type BarProps = {
    link: string,
    title: string
} 

export default function Bar({link, title}: BarProps) {
    return (
        <section className='bar'>
            <a href={link}> 
                {title}
            </a> 
        </section>
    );
}