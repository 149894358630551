import './spreadsheet.css'
import { stampsSimpleFiltred } from '../../api/stamps';
import React, {useEffect, useState} from "react";
import Loading from '../../components/Loading/loading';
import Navbar from '../../components/Navbar/navbar';
import { useParams } from 'react-router-dom';

export default function CategorieSimulation() {
    const { id } = useParams();
    const [responseStamps, setResponseStamps] = useState([]);
    const [activeLoading, setActiveLoading] = useState(true)

    const idParams = id ? parseInt(id) : 0;

    useEffect(() => {
        const fetchData = async () => {
            const response = await stampsSimpleFiltred(idParams);
            setResponseStamps(response);
        }

        fetchData();
        setActiveLoading(false);
    }, []);

    const handleViewNextScreen = (id: number) => {
        window.location.href = `/simulacao/selo/categoria/${id}`
    }

    return (
        <div className='view-stages'>
            <Navbar title="Sistema AMZ" user={true} search={true} />
            <Loading activeProps={activeLoading}/>
            <section className='section-view-stages'>
                <div className='col-title-image-view-stages'>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image"
                    />
                    <h2>Categorias</h2>
                    <hr className='divider-title' style={{ 'width': '90%' }} />
                </div>
                <div className='row-view-stages' style={{'paddingBottom': 0}}>
                    <h3 style={{'width': 'auto'}}>
                        id
                    </h3>
                    <h3 style={{'width': '200px', 'textAlign': 'left'}}>
                        categoria
                    </h3>
                    <h3 style={{'width': 'auto', 'marginLeft': '40px'}}>
                        ações
                    </h3>
                </div>
                {responseStamps.map((stamp: any) => (
                    <div className='col-users'>
                        <div className='row-view-stamps'>
                            <p key={stamp.id} className='id-class'>
                                {stamp.id}
                            </p>
                            <p className='p-name-stage' key={stamp.id} style={{'width': '200px', 'textAlign': 'left'}}>
                                {stamp.categorie}
                            </p>
                            <button className='button-view-stage'>
                                <img src="/olho_negro.svg" alt="olho_negro" className='img-icon-view' onClick={() => handleViewNextScreen(stamp.id)} />
                            </button>
                        </div>
                        <hr className='divider-stage' style={{'marginBottom': 0}} />
                    </div>
                ))}
            </section>
        </div>
    );
}