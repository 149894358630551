import './App.css';
import { profile } from './api/profile';
import React, { useState, useEffect } from 'react';
import Home from './pages/Home/home' 
import LoginScreen from './pages/Login/LoginScreen';
import RegisterClient from './pages/RegisterClient/registerClient';
import ResetPasswordScreen from './pages/ResetPassword/resetPassword';
import CompleteResetPasswordScreen from './pages/CompleteResetPassword/completeResetPassword';
import Spreadsheet from './pages/Spreadsheet/spreadsheet' 
import CreateSpreadsheet from './pages/Spreadsheet/CreateSpreadsheet/createSpreedsheet';
import ListSpreadsheets from './pages/Spreadsheet/ListSpreadsheets/listSpreadsheets';
import RegisterSpreadsheets from './pages/Spreadsheet/RegisterSpreadsheets/registerSpreadsheets';
import ScreenReports from './components/ScreenReports/screenReports';
import NewSpreadsheet from './pages/Spreadsheet/new_spreadsheet' 
import Stages from './pages/Stages/stages' 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PersonalData from './pages/PersonalData/personalData';
import AlterPassword from './pages/AlterPassword/alterPassword';
import AdminViewUsers from './pages/PersonalData/AdminPersonalData/adminViewUsers';
import ViewStage from './pages/Stages/ViewStage/viewStage';
import ScreenIndicator from './pages/ScreenIndicator/screenIndicator';
import ReportProblem from './pages/ReportProblem/reportProblem';
import Simulation from './pages/Spreadsheet/simulation';
import CategorieSimulation from './pages/Spreadsheet/simulationSelectionCategorie';
import UserSimulation from './pages/Spreadsheet/simulationSelectionCategorie';
import SimulationAudiction from './pages/Spreadsheet/RegisterSpreadsheets/simulationAudiction';
import Relatory from './pages/Relatory/relatory';
import RelatoryList from './pages/Relatory/relatoryList';
import Dashboard from './pages/Dashboard/dashboard';
import ResultsList from './pages/Dashboard/listResults';
import Certification from './components/Certification/certification';
import Monitoring from './pages/Monitoring/monitoring';

function App() {
  const [requestUser, setRequestUser] = useState([]);
  const [profileSystem, setProfileSystem] = useState('');

  useEffect(() => {
    const fetchData = async () => {
        try {
            const profileRequest = await profile('profile');
            setRequestUser(profileRequest['cliente']);
            setProfileSystem(profileRequest['perfil']);
        } catch (error) {
            console.error('Erro ao buscar perfil:', error);
        }
    };

    fetchData();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={<LoginScreen />} />
        <Route path='/cadastrar' element={<RegisterClient func='client' url='/' />} />
        <Route path='/trocar-senha' element={<ResetPasswordScreen />} />
        <Route path='/nova-senha/:id/:token' element={<CompleteResetPasswordScreen />} />
        <Route path='/pagina-inicial' element={<Home />} />
        <Route path='/etapas' element={<Stages />} />
        <Route path='/:name_table/:id' element={<ViewStage />} />
        <Route path='/indicador/:name_page/:id' element={<ScreenIndicator />} />
        <Route path='/usuarios' element={<AdminViewUsers />} />
        <Route path='/cadastrar-usuario' element={<RegisterClient func='admin' url='/usuarios' />} />
        <Route path='/dados-pessoais' element={<PersonalData user={requestUser} profile={profileSystem}/>} />
        <Route path='/alterar-senha' element={<AlterPassword />} />
        <Route path='/relatar-problema' element={<ReportProblem/>} /> 
        <Route path='/relatar-problema/:email' element={<ReportProblem/>} /> 
        <Route path='/planilhas' element={<Spreadsheet />} />
        <Route path='/planilhas/criar-planilha' element={<CreateSpreadsheet />} />
        <Route path='/planilhas/:name_consult' element={<ListSpreadsheets />} />
        <Route path='/planilhas/consultoria/:id' element={<RegisterSpreadsheets />} />
        <Route path='/relatorios' element={<ScreenReports />} />
        <Route path='/lista-relatorios' element={<RelatoryList />} />
        <Route path='/relatorio' element={<Relatory />} />
        <Route path='/relatorio/:id' element={<Relatory />} />
        <Route path='/resultados/:id' element={<Dashboard />} />
        <Route path='/resultados' element={<ResultsList />} />
        <Route path='/gerar' element={<NewSpreadsheet />} />
        {/* <Route path='/sivicultura' element={<Stages />} />
        <Route path='/area_legal' element={<Stages />} />
        <Route path='/comunidade_ativa' element={<Stages />} />
        <Route path='/empreendedor_ativo' element={<Stages />} />
        <Route path='/empresa_econsciente' element={<Stages />} />
        <Route path='/governanca_esg' element={<Stages />} />
        <Route path='/eventos_sustentaveis' element={<Stages />} /> */}
        <Route path='/simulacao' element={<Simulation />} />
        <Route path='/simulacao/selo/:id' element={<CategorieSimulation />} />
        <Route path='/simulacao/selo/categoria/:id' element={<SimulationAudiction />} />
        <Route path='/certificacao' element={<Certification />} />
        <Route path='/monitoramento' element={<Monitoring />} />
      </Routes>
    </Router>
  );
}

export default App;
