import './createSpreadsheet.css';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { registerProject } from '../../../api/spreadsheets';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import MessageCancel from '../../../components/CardMessage/messageCancel';
import MessageRequest from '../../../components/CardMessage/MessageRequest/messageRequest';
import Navbar from '../../../components/Navbar/navbar';
import Loading from '../../../components/Loading/loading';
import { stages, categories } from '../../../api/categoriesStages';
import { cnpjUser } from '../../../api/cnpjUsers';
import { registerUserLogs } from '../../../api/userLogs';

export default function CreateSpreadsheet({url}: {url?: string}) {
    const [inputName, setinputName] = useState<string>('');
    const [inputStakeholder, setinputStakeholder] = useState<string>('');
    const [errorName, setErrorName] = useState<string | null>(null);
    const [errorStakeholder, setErrorStakeholder] = useState<string | null>(null);
    const [errorController, setErrorController] = useState<string | null>(null);
    const [activeMessage, setActiveMessage] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<boolean>(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const [activeLoading, setActiveLoading] = useState<boolean>(false);
    const [idProject, setIdProject] = useState<number>(0);
    const [categoriesData, setCategoriesData] = useState<any>([]);
    const [selectedStages, setSelectedStages] = useState<number[]>([]);
    const [selectedCategoriesData, setSelectedCategoriesData] = useState<number[]>([]);
    const [dataStages, setDataStages] = useState<any>([]);
    const navigate = useNavigate();
    const [dataUserCnpj, setDataUserCnpj] = useState([]);

    useEffect(() => {
        const cnpjData = async () => {
            try {
                const cnpjRequest: any = await cnpjUser();
                setDataUserCnpj(cnpjRequest);
            } catch(e) {
                return [];
            }
        }
        cnpjData();
    }, []);

    const handleCheckboxChangeStages = (stageId: number) => {
        if (selectedStages.includes(stageId)) {
            setSelectedStages(selectedStages.filter(id => id !== stageId));
        } else {
            setSelectedStages([...selectedStages, stageId]);
        }
    };

    const handleCheckboxChangeCategoriesData = (categoryId: number) => {
        if (selectedCategoriesData.includes(categoryId)) {
            setSelectedCategoriesData(selectedCategoriesData.filter(id => id !== categoryId));
        } else {
            setSelectedCategoriesData([...selectedCategoriesData, categoryId]);
        }
    };

    const handleInputName = (event: ChangeEvent<HTMLInputElement>) => {
        setinputName(event.target.value);
        setErrorName(null);
    }

    const handleInputStakeholder = (event: any) => {
        setinputStakeholder(event.target.value);
        setErrorStakeholder(null);
    }

    const clearInputs = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        setinputName('');
        setinputStakeholder('');
    }

    const handleRegisterProject = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputName.trim() === '') {
            setErrorName('Campo obrigatório.')
            return;
        } else {
            setErrorName('')
        }

        if (inputStakeholder.trim() === '') {
            setErrorStakeholder('Campo obrigatório.')
            return;
        } else {
            setErrorStakeholder('')
        }

        setActiveLoading(true);

        const responseRegisterProject: any = await registerProject(inputName, inputStakeholder, selectedCategoriesData, selectedStages);
        
        if (responseRegisterProject.status === 201) {
            setActiveLoading(false);
            setIdProject(responseRegisterProject.data['dados']['id']);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Projeto criado com sucesso.');
            registerUserLogs("Criou projeto para auditoria.");
        } else {
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Cadastro inválido. Revise os dados e tente novamente.')
        }
    }

    const handleViewProject = () => {
        navigate(`/planilhas/consultoria/${idProject}`)
    }

    useEffect(() => {
        const fetchData = async () => {
            const responseStage = await stages(); 

            setDataStages(responseStage)

            const responseCategoriesData = await categories();
            
            setCategoriesData(responseCategoriesData)
        }

        fetchData();
    }, []);


    const classNameName = errorName ? 'input-create-spreadsheet-error' : 'input-create-spreadsheet';
    const classNameStakeholder = errorStakeholder ? 'input-create-spreadsheet-error' : 'input-create-spreadsheet';
    const classNameController = errorController ? 'input-create-spreadsheet-error' : 'input-create-spreadsheet';
    
    return (
        <div className='create-spreadsheet-col'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Nova Auditoria" user={true} buttonClear={true} clearInputs={clearInputs} />
            <section>
                <section className='section-create-spreadsheet'>
                    <MessageCancel activeProps={activeMessage} reloadProps={reloadMessage} />
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                    <div className="create-spreadsheet-box">
                        <div className='col-title-sub-title'>
                            <div className='col-title-image-view-stages'>
                                <img
                                    src="/amz.png"
                                    alt="Imagem de cadastro"
                                    className="register-image"
                                />
                                <h2 style={{'width': '250px', 'marginRight': '10px', textAlign: 'left'}}>Dados Cadastrais</h2>
                                <hr className='divider-title' style={{ 'width': '90%' }} />
                            </div>
                            <p className='p-message-spreadsheet'>
                                *Os campos sinalizados em <p className='p-message-red'>VERMELHO</p> são obrigatórios.
                            </p>
                        </div>
                        <form className='form-create-spreadsheet'>
                            <div className='row-childs-spreadsheet'>
                                <div className='col-childs-spreadsheet' style={{'marginLeft': '30px'}}>
                                    <div className='row-label'>
                                        <label className='label-create-spreadsheet'>Nome do Projeto</label>
                                        <p className='required'>*</p>
                                    </div>
                                    <input type="text" onChange={handleInputName} className={classNameName} value={inputName}/>
                                    <span>{errorName}</span>
                                    <div className='row-label'>
                                            <label className='label-create-spreadsheet'>Responsável</label>
                                            <p className='required'>*</p>
                                        </div>
                                        <select className="select-register" style={{ width: "411px" }} value={inputStakeholder} onChange={handleInputStakeholder}>
                                            <option value="">Selecione...</option>
                                            {dataUserCnpj.map((user: any, index: number) => (
                                                <option key={index} value={user.id}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                            </div>
                            <div className='col-title-sub-title' style={{'marginTop': '30px'}}>
                                <div className='col-title-image-view-stages'>
                                    <img
                                        src="/amz.png"
                                        alt="Imagem de cadastro"
                                        className="register-image"
                                    />
                                    <h2 style={{'width': '100px', 'marginRight': '10px'}}>Etapas</h2>
                                    <hr className='divider-title' style={{ 'width': '90%'}} />
                                </div>
                            </div>
                            <div className='row-checkbox'>
                                <div className='col-checkbox'>
                                    <label className='label-create-spreadsheet' style={{'marginTop': '30px', 'marginLeft': '30px'}}>Etapas</label>
                                    <ul style={{margin: 0, 'marginLeft': '30px'}}>
                                        {dataStages.map((stage: any) => (
                                        <li key={stage.id}>
                                            <label style={{"fontSize": '14px', 'display': 'flex', 'alignItems': 'center', 'color': "#000000"}}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedStages.includes(stage.id)}
                                                    onChange={() => handleCheckboxChangeStages(stage.id)}
                                                />
                                                {stage.stage}
                                            </label>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='col-checkbox'>
                                    <label className='label-create-spreadsheet' style={{'marginTop': '30px', 'marginLeft': '30px'}}>Categorias Específicas</label>
                                    <ul style={{margin: 0, 'marginLeft': '30px'}}>
                                        {categoriesData.map((category: any) => (
                                        <li key={category.id}>
                                            <label style={{"fontSize": '14px', 'display': 'flex', 'alignItems': 'center', 'color': "#000000"}}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCategoriesData.includes(category.id)}
                                                    onChange={() => handleCheckboxChangeCategoriesData(category.id)}
                                                />
                                                {category.categorie}
                                            </label>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='div-button-create-spreadsheet'>
                                <div className='div-two-button-spreadsheet'>
                                    <button className='button-view-spreadsheet' onClick={handleViewProject}>visualizar</button>
                                    <button className='button-create-spreadsheet' onClick={handleRegisterProject}>salvar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </section>
        </div>
    )
}