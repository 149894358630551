import './modalUser.css';
import React, { useState, useEffect } from 'react';
import ColProfile from '../ColProfile/colProfile';

type ModalUserProps = {
    activeProps: boolean,
    open: any
}

export default function ModalUser({activeProps, open}: ModalUserProps) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(activeProps);
    }, [activeProps]);

    const handleDeactivate = () => {
        setActive(false);
        open(false);
    }
    
    return(
        <div className={active === true ? 'card-modal' : 'card-modal-none'}>
            <div className='modal'>
                <div className='col-modal-general'>
                    <div className='col-modal'>
                        <a className='button-card-user' href='/dados-pessoais'>Perfil</a>
                        <a className='button-card-user' href='/dados-pessoais'>Configurações</a>
                        <a className='button-card-user' href='/relatar-problema' style={{'marginBottom': '50px'}}>Relatar Problema</a>
                    </div>
                    <button onClick={handleDeactivate} style={{'textAlign': 'right', 'padding': '0'}}>x</button>
                </div>
                <div className='col-modal-general' style={{ justifyContent: "center" }}>
                    <ColProfile />
                </div>
            </div>
        </div>
    );
}