import React from 'react';
import './spreadsheet.css'
import ScreenOptions from '../ScreenOptions/screenOptions';

export default function Spreadsheet() {
    return (
        <div>
             {localStorage.getItem('profile') === 'camara' ? (<ScreenOptions title="Auditorias" nameButton1="auditorias finalizadas" href1='/planilhas/finalizadas' nameButton2="auditorias aprovadas" href2='/planilhas/aprovadas' nameButton3="rascunhos" href3='/planilhas/rascunhos' />) : (<ScreenOptions title="Auditorias" nameButton1="abrir nova auditoria" href1="/planilhas/criar-planilha" nameButton2="consultar auditoria" href2="/planilhas/consultar_planilhas" nameButton3="anexar para auditoria" href3="/certificacao" nameButton4="auditorias finalizadas" href4='/planilhas/finalizadas' nameButton5="auditorias ativas" href5='/planilhas/ativas' nameButton6="rascunhos" href6='/planilhas/rascunhos' />)}
        </div>
    );
}