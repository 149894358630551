import axios from 'axios';

export async function resultAudit(project_id: string) {
    try {
        const indicatorRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/results/',
            {
                project_id: parseInt(project_id)  
            },
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }                       
            });
        if (indicatorRequest.status === 200) {
            return indicatorRequest
        }       
    } catch (error) {
        return []
    }
}