import './colProfile.css'
import { profile } from '../../api/profile';
import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../api/logout';
import { finishProject, approveProject } from '../../api/spreadsheets';
import MessageRequest from '../CardMessage/MessageRequest/messageRequest';

export default function ColProfile({idProject, finish}: {idProject?: number,finish?: boolean}) {
    const [nameProfile, setNameProfile] = useState<string>('');
    const [buttonFinish, setButtonFinish] = useState<boolean>(finish || false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileRequest = await profile('profile');
                setNameProfile(profileRequest['cliente']['name']);
            } catch (error) {
                console.error('Erro ao buscar perfil:', error);
            }
        };

        fetchData();
    }, []);

    const handleClickLogout = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        
        const responseLogout: any = await logout();

        if (responseLogout.status === 200) {
            localStorage.clear();
            navigate('/');
        }
    }

    const handleClickFinishProject = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        
        const responseFinish: any = await finishProject(idProject || 0);

        if (responseFinish.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Projeto finalizado com sucesso.');
        }
    }

    const handleClickApproveProject = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        
        const responseApprove: any = await approveProject(idProject || 0);

        if (responseApprove.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Projeto aprovado com sucesso.');
        }
    }

    return (
        <div className='col-profile'>
            {activeMessageRequest && (
                <div className="col-message-approve">
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                </div>
            )}
            <button className='button-register' style={finish ? {display: "none"} : {display: "block"}} onClick={handleClickLogout}>Sair</button>
            {(localStorage.getItem('profile') === 'auditor' || localStorage.getItem('profile') === 'administrador') && (
                <button className='button-register' style={finish ? {display: "block", width: "auto"} : {display: "none"}} onClick={handleClickFinishProject}>Finalizar</button>
            )}
            {localStorage.getItem('profile') === 'camara' && (
                <button className='button-register' style={finish ? {display: "block", width: "auto"} : {display: "none"}} onClick={handleClickApproveProject}>Aprovar</button> 
            )}
            <p className='p-npca'>
                © NPCA 
            </p>
        </div>
    );
}