import Loading from "../../components/Loading/loading";
import Navbar from "../../components/Navbar/navbar";
import React, { useState, useEffect } from "react";
import { filterProjectsFinisheds } from "../../api/spreadsheets";

export default function ResultsList() {
    const [results, setResults] = useState<any>([]);
    const [activeLoading, setActiveLoading] = useState<boolean>(true)
    const [searchData, setSearchData] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const reportssRequest = await filterProjectsFinisheds(true)
                
            setResults(reportssRequest);
            if (results !== null || results !== '') {
                setActiveLoading(false)
            } 
        };

        fetchData();
    });

    const handleViewNextScreen = (seleted: any) => {
        window.location.href = `/resultados/${seleted['id']}`
    }

    const handleSearch = (event: any) => {
        setSearchData(event.target.value)
    }
    
    const resultsFiltred = results.filter((result: any) => result.name.toLowerCase().startsWith(searchData.toLowerCase()));

    return (
        <div className='view-stages'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Sistema AMZ" user={true} search={true} onChangeSearch={handleSearch} />
            <section className='section-view-stages'>
                <div className='col-title-image-view-stages'>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image"
                    />
                    <h2>Resultados</h2>
                    <hr className='divider-title' style={{ 'width': '90%' }} />
                </div>
                <div className='row-view-stages' style={{'paddingBottom': 0}}>
                    <h3 style={{'width': '140px'}}>
                        Empresa
                    </h3>
                    <h3 style={{width: '80px'}}>
                        ações
                    </h3>
                </div>
                <div className='col-users'>
                    {resultsFiltred ? resultsFiltred.map((result: any, index: number) => (
                        <div className='col-users'>
                            <div className='row-view-stages' style={{'height': '40px'}}>
                                <p key={index} style={{'width': '600px'}}>
                                    {result.name}
                                </p>
                                <div className='row-buttons-action'>
                                    <button className='button-view-stage'>
                                        <img src="/olho_negro.svg" alt="olho_negro" className='img-icon-view' onClick={() => handleViewNextScreen(result)} />
                                    </button>
                                </div>
                            </div>
                            <hr className='divider-stage' style={{'marginBottom': 0}} />
                        </div>
                    )) : 
                        []
                    }
                    {results.length === 0 && (<div className="div-data-null">
                        <p>Não há dados cadastrados</p>
                    </div>)}
                </div>
            </section>
        </div>
    );
}