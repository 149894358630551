import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './screenIndicator.css'
import Navbar from '../../components/Navbar/navbar' 
import ColProfile from '../../components/ColProfile/colProfile';
import ModalViewStage from '../../components/ModalViewStage/modalViewStage';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { indicatorsFiltred } from '../../api/indicators';
import { stamps } from '../../api/stamps';
import { categories } from '../../api/categoriesStages';
import Drawer from '@mui/material/Drawer';

export default function ScreenIndicator() {
    const navigate = useNavigate();
    const { name_page, id } = useParams();
    const [namePage, setNamePage] = useState<string>('')
    const [stages, setStages] = useState<any>([]);
    const [stampsData, setStampsData] = useState<any>([]);
    const [categoriesData, setCategoriesData] = useState<any>([]);
    const [categoriesDataRegisterStampsData, setCategoriesDataRegisterStampsData] = useState<any>([]);
    const [activeModalRegister, setActiveModalRegister] = useState<boolean>(false);
    const [titleRegister, setTitleRegister] = useState<string>('');
    const [indicator, setIndicator] = useState<any>([])
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const list_stage = window.location.href.split('/')
    const stage = list_stage[list_stage.length - 1]

    const [cardVisible, setCardVisible] = useState(true);
    const [activeLoading, setActiveLoading] = useState(true)

    const handleButtonClick = () => {
        setCardVisible(!cardVisible);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (name_page === 'tabela-etapas' || name_page === 'tabela-categorias') {
                setNamePage('Etapa');
            } else if (name_page === 'tabela-selos') {
                setNamePage('Categoria');
            }
            const idParams = id ? parseInt(id) : 0;
            const responseIndicator = await indicatorsFiltred(idParams);
            setIndicator(responseIndicator)

            const responseStampsData = await stamps();
            setStampsData(responseStampsData)

            const responseCategories = await categories();
            setCategoriesData(responseCategories)
        }

        fetchData();
        
    }, []);

    const linksStages = [];
    const linksStampsData = [];
    const linksCategoriesData = [];

    for (let stag = 0; stag < stages.length; stag++) {
        linksStages.push({ text: stages[stag].stage.toLowerCase(), href: '/tabela-etapas/'+stages[stag].id });
    }

    for (let stam = 0; stam < stampsData.length; stam++) {
        linksStampsData.push({ text: stampsData[stam].stamp.toLowerCase(), href: '/tabela-selos/'+stampsData[stam].id });
    }

    for (let cate = 0; cate < categoriesData.length; cate++) {
        linksCategoriesData.push({ text: categoriesData[cate].categorie.toLowerCase(), href: '/tabela-categorias/'+categoriesData[cate].id });
    }

    const divideColumns = (array: any, tamColumn: number) => {
        const columns = [];
        for (let i = 0; i < array.length; i += tamColumn) {
            columns.push(array.slice(i, i + tamColumn));
        }
        return columns;
    };

    const columnsLinksStages = divideColumns(linksStages, 2);
    const columnsLinksStampsData = divideColumns(linksStampsData, 2);
    const columnsLinksCategoriesData = divideColumns(linksCategoriesData, 2);

    const handleActiveRegisterStage = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(true);
        setTitleRegister('NOVA ETAPA');
        setCategoriesDataRegisterStampsData([]);
    }

    const handleActiveRegisterCategorie = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(true);
        setTitleRegister('NOVA CATEGORIA');
        setCategoriesDataRegisterStampsData([]);
    }

    const handleActiveRegisterStampsData = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(true);
        setTitleRegister('NOVO SELO');
        setCategoriesDataRegisterStampsData(categoriesData);
    }

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div className='stages'>
            <Navbar title="Sistema AMZ" user={true} search={true} menu={toggleDrawer(true)} />
            <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                <div className="sidebar">
                    <div className="menu_stages">
                        <a className={stage === 'pagina-inicial' ? 'selected' : ''} onClick={handleBack}>Voltar</a>
                        <a className={name_page === 'tabela-etapas' || name_page === 'tabela-categorias' || name_page === 'tabela-selos' ? 'selected' : ''} href="etapas">{namePage}</a>
                    </div>
                    <ColProfile />
                </div>
            </Drawer>
            <div className='container-sidebar-register'>
                <div className="sidebar">
                    <div className="menu_stages">
                        <a className={stage === 'pagina-inicial' ? 'selected' : ''} onClick={handleBack}>Voltar</a>
                        <a className={name_page === 'tabela-etapas' || name_page === 'tabela-categorias' || name_page === 'tabela-selos' ? 'selected' : ''} href="etapas">{namePage}</a>
                    </div>
                    <ColProfile />
                </div>
            </div>
            <section className='section-stages'>
                <ModalViewStage activeModal={cardVisible} indicator={indicator} namePage={namePage}/>
            </section>
        </div>
    );
}