import React, { useState, useEffect } from "react";
import Loading from "../Loading/loading";
import Navbar from "../Navbar/navbar";
import FilesUploadArea from "../FileUploadArea/filesUploadArea";
import ColProfile from "../ColProfile/colProfile";
import MessageRequest from "../CardMessage/MessageRequest/messageRequest";
import "./certification.css"
import Drawer from '@mui/material/Drawer';
import { cnpjUser } from "../../api/cnpjUsers";
import { stagesSimple } from "../../api/categoriesStages";

export default function Certification() {
    const list_page = window.location.href.split('/')
    const page = list_page[list_page.length - 1]
    const [activeLoading, setActiveLoading] = useState(false)
    const [nameFile, setNameFile] = useState<string>('')
    const [fileUpload, setFileUpload] = useState<any>([])
    const [update, setUpdate] = useState<boolean>(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState('');
    const [messageRequest, setMessageRequest] = useState('');
    let idUser: string | null = localStorage.getItem("id");
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [inputStakeholder, setinputStakeholder] = useState<string>('');
    const [dataUserCnpj, setDataUserCnpj] = useState([]);
    const [stages, setStages] = useState([]);
    const [stageId, setStageId] = useState<number>(0);
    const list_stage = window.location.href.split('/')
    const stage = list_stage[list_stage.length - 2]
    const [errorStakeholder, setErrorStakeholder] = useState<string | null>(null);

    useEffect(() => {
        const cnpjData = async () => {
            try {
                const cnpjRequest: any = await cnpjUser();
                setDataUserCnpj(cnpjRequest);

                const stagesRequest: any = await stagesSimple();
                setStages(stagesRequest);
            } catch(e) {
                return [];
            }
        }
        cnpjData();
    }, []);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const handleFileSelect = (file: any) => {
        setNameFile(file.name);
        setFileUpload(file);
    };

    const handleActiveMessage = (response: any) => {
        setActiveMessageRequest(false);
        setActiveMessageRequest(true);
        if (response) {
            setTitleMessageRequest("Sucesso");
            setMessageRequest("A importação foi um sucesso.");
        } else {
            setTitleMessageRequest("Erro");
            setMessageRequest("Verifique os dados e tente novamente.");
        }
    }

    const handleStageSelect = (event: any) => {
        setStageId(event.target.value);
    };

    const handleInputStakeholder = (event: any) => {
        setinputStakeholder(event.target.value);
        setErrorStakeholder(null);
    }

    return (
        <div className='home'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Sistema AMZ" user={true} search={true} menu={toggleDrawer(true)} />
            <div className='container-sidebar'>
                <div className="sidebar">
                    {localStorage.getItem('profile') !== 'cliente' ? (
                            <div className="menu_stages">
                                <a className={stage === 'pagina-inicial' ? 'selected' : ''} href="/planilhas">Voltar</a>
                            </div>
                        ) : (
                            <div className="menu_stages">
                                <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                                <a  href="dados-pessoais">Dados Pessoais</a>
                                <a className={page === 'simulacao' ? 'selected' : ''} href="simulacao">Simulação</a>
                                <a className={page === 'certificacao' ? 'selected' : ''} href="certificacao">Certificação</a>
                            </div>
                        )
                    }
                    <ColProfile />
                </div>
            </div>
            {localStorage.getItem('profile') === 'cliente' && (
                <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                    <div className="sidebar-home">
                        <div className="menu_stages">
                            <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                            <a  href="dados-pessoais">Dados Pessoais</a>
                            <a className={page === 'simulacao' ? 'selected' : ''} href="simulacao">Simulação</a>
                            <a className={page === 'certificacao' ? 'selected' : ''} href="certificacao">Certificação</a>
                        </div>
                        <ColProfile />
                    </div>
                </Drawer>
            )}
            <section className='section-home'>
                <div className="area-message-certification">
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                </div>
                <div className="container-certification">
                    <div className='col-title-image-view-stages'>
                        <img
                            src="/amz.png"
                            alt="Imagem de cadastro"
                            className="register-image"
                        />
                        {localStorage.getItem('profile') === 'cliente' ? (
                            <h2>Certificação</h2>
                        ) : (
                            <h2>Documentação</h2>
                        )}
                        <hr className='divider-title' style={{ 'width': '90%' }} />
                    </div>
                    <div>
                        <div className='row-label' style={{marginTop: "20px"}}>
                            <label className='label-create-spreadsheet'>Etapa</label>
                            <p className='required'>*</p>
                        </div>
                        <select className="select-register" style={{ width: "411px", marginBottom: "10px" }} value={stageId} onChange={handleStageSelect}>
                            <option value="">Selecione...</option>
                            {stages.map((stage: any, index: number) => (
                                <option key={index} value={stage.id}>
                                    {stage.stage}
                                </option>
                            ))}
                        </select>
                    </div>
                    {localStorage.getItem('profile') === 'cliente' ? (
                        <p style={{ textAlign: "justify", marginBottom: "10px" }}>Olá, se você chegou até aqui então passou pelo primeiro passo necessário para tirar sua Certificação que é o <b>cadastro</b>. Para prosseguir, você deve fornecer no mínimo <b>60%</b> da documentação necessária para o selo pretendido. Caso não saiba quais os documentos necessários para o selo de sua preferência, basta fazer a <b>simulação</b>. Lá estão as categorias dos selos e suas devidas etapas, contendo os requisitos necessários, a partir daí será possível ter auxílio sobre os documentos que serão requisitados. Com posse da documentação, faça a importaçao no espaço abaixo, de preferência no formato de imagem (jpeg, jpg, png). Após importar todos os seus arquivos, clique em <b>salvar</b>.</p>
                    ) : (
                        <div>
                            <div className='row-label' style={{marginTop: "20px"}}>
                                <label className='label-create-spreadsheet'>Cliente</label>
                                <p className='required'>*</p>
                            </div>
                            <select className="select-register" style={{ width: "411px", marginBottom: "10px" }} value={inputStakeholder} onChange={handleInputStakeholder}>
                                <option value="">Selecione...</option>
                                {dataUserCnpj.map((user: any, index: number) => (
                                    <option key={index} value={user.id}>
                                        {user.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    
                    <FilesUploadArea onFileSelect={handleFileSelect} nameFile={nameFile} update={update} idUser={localStorage.getItem('profile') === 'cliente' ? idUser : inputStakeholder} idStage={stageId === 0 ? 1 : stageId} response={(response: any) => handleActiveMessage(response)} />
                </div>
            </section>
        </div>
    );
}   