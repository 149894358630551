import './LoginScreen.css';
import React, { useState, ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/login';
import { registerUserLogs } from '../../api/userLogs';

export default function LoginScreen() {
  const [inputEmail, setinputEmail] = useState<string>('');
  const [inputPassword, setinputPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleInputEmail = async (event: ChangeEvent<HTMLInputElement>) => {
    setinputEmail(event.target.value);
    setErrorEmail(null);
  }

  const handleInputPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setinputPassword(event.target.value);
    setErrorPassword(null);
  }

  const handleSubmit = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputEmail.trim() === '') {
      setErrorEmail('Campo obrigatório.')
      return;
    } else {
      setErrorEmail('')
    }

    if (inputPassword.trim() === '') {
      setErrorPassword('Campo obrigatório.')
      return;
    } else {
      setErrorPassword('')
    }

    const responseLogin: any = await login(inputEmail, inputPassword);
    
    if (responseLogin.status === 200) {
        localStorage.setItem('access_token', responseLogin.data['access'])
        localStorage.setItem('refresh_token', responseLogin.data['refresh'])
        localStorage.setItem('profile', responseLogin.data['profile'])
        navigate('/pagina-inicial');
        registerUserLogs("Fez Login.");
    } else {
      setErrorPassword('E-mail e/ou senha inválidos.');
    }
  }

  const classNameEmail = errorEmail ? 'input-error' : 'input';
  const classNamePassword = errorPassword ? 'input-error' : 'input';

  return (
    <div className="login-screen">
      
      <div className="login-box">
        <div className='col-1'>
          <img
            src="/login-image.png"
            alt="Imagem de login"
            className="login-image"
          />
        </div>
        <div className="vertical-line"></div> {}
        <div className='col-2'>
          <h2>LOGIN</h2>
          <form>
            <label>email</label>
            <input type="text" onChange={handleInputEmail} className={classNameEmail}/>
            <span>{errorEmail}</span>
            <label>senha</label>
            <div className='input-image'>
              <input type={showPassword ? 'text' : 'password'} id='password' onChange={handleInputPassword} className={classNamePassword} />
              <a onClick={togglePasswordVisibility}>
                <img src="SVGOlhoBranco.svg" alt="ver" />
              </a>
            </div>
            <span style={{'marginTop': '40px'}}>{errorPassword}</span>
            <div className='col-register'>
              <a href="/trocar-senha" className='link-login'>esqueci minha senha</a>
            </div>
            <div className='div-button'>
              <button onClick={handleSubmit} className='button-login'>entrar</button>
            </div>
            <div className='col-register'>
              <p className='p-login' style={{'marginRight': '15px'}}>não é cadastrado?</p>
              <a href="/cadastrar" className='link-login'>cadastrar</a>
            </div>
          </form>
        </div>
      </div>
      <p className='p-npca'>
        © NPCA 
      </p>
    </div>
  );
}
