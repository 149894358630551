import React, { useRef } from 'react';
import './fileUploadButton.css'

type FileUploadButtonProps = {
    onFileSelect: any
}

const FileUploadButton = ({ onFileSelect }: FileUploadButtonProps) => {
    const fileInputRef: any = useRef(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileSelect = (event: any) => {
        const file = event.target.files[0];
        onFileSelect(file);
    };

    return (
        <div>
            <input className='input-upload' type="file" onChange={handleFileSelect} ref={fileInputRef} style={{'display': 'none'}} />
            <button type="button" className='button-upload' onClick={handleButtonClick}>selecionar arquivo</button>
        </div>
    )
};

export default FileUploadButton;