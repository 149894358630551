import './listSpreadsheets.css'
import Loading from '../../../components/Loading/loading';
import Navbar from '../../../components/Navbar/navbar';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { filterProjectsActives, filterProjectsFinisheds, projects, filterProjectsApproveds } from '../../../api/spreadsheets';

function createdAt(date: any) {
    const new_date = date.split('-');

    return `${new_date[2][0]+new_date[2][1]}/${new_date[1]}/${new_date[0]}`
}

export default function ListSpreadsheets() {
    const { name_consult } = useParams();
    const [projectsData, setProjectsData] = useState([]);
    const [stageSelected, setStageSelected] = useState([]);
    const [namePage, setNamepage] = useState('');
    const [nameNewButton, setNameNewButton] = useState('');
    const [titleModal, setTitleModal] = useState('');
    const [titleModalEdit, setTitleModalEdit] = useState('');
    const [activeLoading, setActiveLoading] = useState(true)
    const [searchData, setSearchData] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (name_consult === 'consultar_planilhas') {
                const projectsDataRequest = await projects();

                setProjectsData(projectsDataRequest);
                if (projectsData !== null || projectsData !== '') {
                    setActiveLoading(false);
                } 
            } else if (name_consult === 'finalizadas') {
                const projectsDataRequest = await filterProjectsFinisheds(true);

                setProjectsData(projectsDataRequest);
                if (projectsData !== null || projectsData !== '') {
                    setActiveLoading(false);
                } 
            } else if (name_consult === 'aprovadas') {
                const projectsDataRequest = await filterProjectsApproveds(true);

                setProjectsData(projectsDataRequest);
                if (projectsData !== null || projectsData !== '') {
                    setActiveLoading(false);
                } 
            } else if (name_consult === 'rascunhos') {
                const projectsDataRequest = await filterProjectsFinisheds(false);

                setProjectsData(projectsDataRequest);
                if (projectsData !== null || projectsData !== '') {
                    setActiveLoading(false);
                } 
            } else if (name_consult === 'ativas') {
                const projectsDataRequest = await filterProjectsActives(true);

                setProjectsData(projectsDataRequest);
                if (projectsData !== null || projectsData !== '') {
                    setActiveLoading(false);
                } 
            } else if (name_consult === 'tabela-categorias') {
                const projectsDataRequest = await projects();

                setProjectsData(projectsDataRequest);
                
                if (projectsData !== null || projectsData !== '') {
                    setActiveLoading(false)
                }
            } else if (name_consult === 'tabela-selos') {
                try {
                    const stageRequest = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/project/list/',
                    {
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem("access_token")
                        }
                    });
                    setProjectsData(stageRequest.data);
                    if (projectsData !== null || projectsData !== '') {
                        setActiveLoading(false)
                    }
                } catch (error) {
                    console.error('Erro ao buscar perfil:', error);
                }
            }
        };

        fetchData();
    }, []);

    const handleViewNextScreen = (seleted: any) => {
        window.location.href = `/planilhas/consultoria/${seleted['id']}`
    }

    const handleSearch = (event: any) => {
        setSearchData(event.target.value)
    }
    
    const projectsDataFiltred = projectsData.filter((project: any) => project.name.toLowerCase().startsWith(searchData.toLowerCase()));

    return (
        <div className='view-stages'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Consultar Audição" user={true} search={true} onChangeSearch={handleSearch} />
            <section className='section-view-stages'>
                <div className='row-view-stages' style={{'paddingBottom': 0}}>
                    <h3 style={{'width': 'auto', 'marginRight': '45px'}} className='title-audiction'>
                        audição
                    </h3>
                    <h3 style={{'width': 'auto', 'marginRight': '50px'}} className='title-id-audiction'>
                        ID
                    </h3>
                    <h3 style={{'width': 'auto', 'marginRight': '10px'}}>
                        auditor
                    </h3>
                    <h3 style={{'marginLeft': '10px'}} className='title-date'>
                        data
                    </h3>
                    <h3>
                        status
                    </h3>
                    <h3 style={{width: '70px', marginLeft: '5px'}}>
                        {}
                    </h3>
                </div>
                <hr className='divider-stage' style={{'marginBottom': 0, 'marginTop': '10px'}} />
                {projectsDataFiltred.map((project: any, index) => (
                    <div className='col-users' key={index}>
                        <div className='row-view-stages' style={{'height': '40px'}}>
                            <p key={index} style={{'width': '165px'}} className='name-project'>
                                {project.name}
                            </p>
                            <p key={index} style={{'width': '20px', 'marginRight': '130px', 'marginLeft': '30px'}} className='id-audiction'>
                                {project.id}
                            </p>
                            <p key={index} style={{'width': '160px'}} className='controller-name'>
                                {project.controller_id_name}
                            </p>
                            <p key={index} style={{'width': '160px'}} className='created-at'>
                                {createdAt(project.created_at)}
                            </p>
                            <p key={index} style={project.is_active === true ? {color: '#006633'} : {color: 'red'}}>
                                {project.is_active === true ? 'ativo' : 'inativo'}
                            </p>
                            <div className='row-buttons-action'>
                                <button className='button-view-stage'>
                                    <img src="/olho_negro.svg" alt="olho_negro" className='img-icon-view' onClick={() => handleViewNextScreen(project)} />
                                </button>
                            </div>
                        </div>
                        <hr className='divider-stage' style={{'marginBottom': 0}} />
                    </div>
                    )) 
                }
            </section>
        </div>
    );
}