import './cardStages.css'
import React from 'react';

type CardStagesProps = {
    title: string, 
    width: string, 
    columnsLinks: any, 
    activeModal: any, 
    nameButton: string
}

export default function CardStages({title, width, columnsLinks, activeModal, nameButton}: CardStagesProps) {
    return(
        <div>
            <div className='col-title-image-stages'>
                <img
                    src="/amz.png"
                    alt="Imagem de cadastro"
                    className="register-image"
                />
                <h2>{title}</h2>
                <hr className='divider-title' style={{ 'width': width }} />
            </div>
            {columnsLinks.map((column: any, index: number) => (
                <div key={index} className='col-cards-stages'>
                {column.map((link: any, subIndex: any) => (
                    <a key={subIndex} href={link.href} className='card-stage'>
                        {link.text}
                    </a>
                ))}
                </div>
            ))}
            <div className='row-button-stage'>
                <button className='button-stage' onClick={activeModal}>{nameButton}</button>
            </div>
        </div>
    );
}