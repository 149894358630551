import './navbar.css'
import React, { useEffect, useState } from 'react';
import ModalUser from '../ModalUser/modalUser';
import ModalRegisterStage from '../ModalRegisterStage/modalRegisterStage';
import ModalImportFile from '../ModalImportFile/modalImportFile';

type NavbarProps = {
    title: string, 
    user?: boolean, 
    search?: boolean, 
    button?: boolean, 
    nameButton?: string, 
    buttonSave?: boolean, 
    functionBack?: any,
    functionSave?: any,
    buttonDownload?: any, 
    functionDownload?: any, 
    buttonClear?: boolean, 
    clearInputs?: any, 
    buttonImport?: boolean, 
    titleModal?: string, 
    indicators?: any, 
    idStage?: number, 
    idCategorie?: number
    onChangeSearch?: any,
    menu?: any
}

export default function Navbar({title, user, search, button, nameButton, buttonSave, functionBack, functionSave, buttonDownload, functionDownload, buttonClear, clearInputs, buttonImport, titleModal, indicators, idStage, idCategorie, onChangeSearch, menu}: NavbarProps) {
    const [activeModalUser, setActiveModalUser] = useState<boolean>(false);
    const [activeModalRegister, setActiveModalRegister] = useState<boolean>(false);
    const [activeModalUpload, setActiveModalUpload] = useState<boolean>(false);

    const handleActiveModal = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setActiveModalUser(!activeModalUser)
    }

    useEffect(() => {
        setActiveModalUser(activeModalUser);
    }, [activeModalUser])

    const handleActiveModalRegister = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(!activeModalRegister);
    };

    useEffect(() => {
        setActiveModalRegister(activeModalRegister);
    }, [activeModalRegister])

    const handleActiveUploadFile = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalUpload(!activeModalUpload);
    };

    useEffect(() => {
        setActiveModalUpload(activeModalUpload);
    }, [activeModalUpload])
    
    return (
        <div className='cols-nav'>
            <div className="nav">
                <div className='col-title-image-nav'>
                    {menu && <button className='button-menu' onClick={menu} style={{width: "auto", height: "auto", padding: 0, margin: 0, marginRight: "20px"}}><img src="/menu.svg" alt="" /></button>}
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image-nav"
                    />
                    <h3> {title} </h3>
                </div>
                <div className={user ? 'row-search-circle-user' : 'row-search-circle-user-none'}>
                    <div className="pesquisa" style={search ? {'display': 'flex'} : {'display': 'none'}}>
                        <input type="text" placeholder="pesquisa" className="input-search" id="searchBar" onChange={onChangeSearch} />
                        <img src="/SVGRepo_iconCarrierLupa.svg" alt="" className="img-search"/>
                    </div>
                    <button type='button' className={button ? 'button-new-indicator' : "row-search-circle-user-none"} onClick={handleActiveModalRegister}>
                        {nameButton}
                    </button>
                    <button type='button' className={buttonSave ? 'button-save' : "row-search-circle-user-none"} onClick={functionBack} style={{ marginRight: '10px' }}>
                        página inicial
                    </button>
                    <button type='button' className={buttonSave ? 'button-save' : "row-search-circle-user-none"} onClick={functionSave}>
                        salvar
                    </button>
                    <button type='button' className={buttonDownload ? 'button-save' : "row-search-circle-user-none"} onClick={functionDownload} style={{ marginLeft: "20px" }}>
                        baixar
                    </button>
                    <button id='button-save' className={buttonClear ? 'button-create-spreadsheet-clear' : "row-search-circle-user-none"} onClick={clearInputs}>
                        limpar tudo
                    </button>
                    <button type='button' className={buttonImport && titleModal !== "Nova Categoria" ? 'button-new-indicator' : "row-search-circle-user-none"} onClick={handleActiveUploadFile} style={{'marginLeft': '10px'}}>
                        importar arquivo
                    </button>
                    <a className='circle-user' onClick={handleActiveModal}>
                        <img src="/SVGRepo_iconCarrier.svg" alt="user"/>
                    </a>
                </div>
            </div>
            <hr className='divider' />
            <ModalUser activeProps={activeModalUser} open={(open: any) => setActiveModalUser(open) } />
            <ModalRegisterStage activeModal={activeModalRegister} titleModal={titleModal !== undefined ? titleModal : ''} indicators={indicators} idStage={idStage !== undefined ? idStage : 0} open={(open: any) => setActiveModalRegister(open)} />
            <ModalImportFile activeModal={activeModalUpload} titleModal={titleModal !== undefined ? titleModal : ''} idStage={idStage !== undefined ? idStage : 0} idCategorie={idCategorie !== undefined ? idCategorie : 0} open={(open: any) => setActiveModalUpload(open)} />
        </div>
    );
}