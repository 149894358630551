// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar {
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
}

.bar a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 100px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    text-decoration: none;
    padding: 15px;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    align-items: center;
}

@media (max-width: 1100px) {
    .bar {
        width: 120px;
    }

    .bar a {
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Bar/bar.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;IACtB,WAAW;IACX,qBAAqB;IACrB,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI;QACI,YAAY;IAChB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".bar {\n    padding-bottom: 10px;\n    padding-left: 10px;\n    display: flex;\n}\n\n.bar a {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 160px;\n    height: 100px;\n    border-radius: 10px;\n    background-color: #fff;\n    border: 1px solid #000;\n    color: #000;\n    text-decoration: none;\n    padding: 15px;\n    font-weight: 500;\n    font-size: 22px;\n    text-align: center;\n    align-items: center;\n}\n\n@media (max-width: 1100px) {\n    .bar {\n        width: 120px;\n    }\n\n    .bar a {\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
