import React from 'react';
import './screenOptions.css'
import Bar from '../../components/Bar/bar' 
import Navbar from '../../components/Navbar/navbar'
import ColProfile from '../../components/ColProfile/colProfile'
import Drawer from '@mui/material/Drawer';

type ScreenOptionsProps = {
    title: string, 
    href1?: string, 
    nameButton1?: string, 
    href2?: string, 
    nameButton2?: string, 
    href3?: string, 
    nameButton3?: string,
    href4?: string,  
    nameButton4?: string,
    href5?: string,  
    nameButton5?: string,
    href6?: string,  
    nameButton6?: string
}

export default function ScreenOptions({title, href1, nameButton1, href2, nameButton2, href3, nameButton3, href4, nameButton4, href5, nameButton5, href6, nameButton6}: ScreenOptionsProps) {
    const list_page = window.location.href.split('/')
    const page = list_page[list_page.length - 1]
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    return (
        <div>
            <div className='options'>
                <Navbar title="Sistema AMZ" user={true} search={true} menu={toggleDrawer(true)} />
                <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                    <div className="sidebar-home">
                        {localStorage.getItem('profile') === 'administrador' && (
                            <div className="menu_stages">
                                <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                                <a  href="dados-pessoais">Dados Pessoais</a>
                                <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                                <a className={page === 'etapas' ? 'selected' : ''} href="etapas">Etapas</a>
                                <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                                <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados </a>
                                <a className={page === 'simulacao' ? 'selected' : ''} href="simulacao">Simulação</a>
                                <a className={page === 'usuarios' ? 'selected' : ''} href="usuarios">Usuários</a>
                            </div>
                        )}
                        {localStorage.getItem('profile') === 'auditor' && (
                            <div className="menu_stages">
                                <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                                <a  href="dados-pessoais">Dados Pessoais</a>
                                <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                                <a className={page === 'etapas' ? 'selected' : ''} href="etapas">Etapas</a>
                                <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                                <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados </a>
                            </div>
                        )}
                        {localStorage.getItem('profile') === 'camara' && (
                            <div className="menu_stages">
                                <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                                <a  href="dados-pessoais">Dados Pessoais</a>
                                <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                                <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                                <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados </a>
                            </div>
                        )}
                        <ColProfile />
                    </div>
                </Drawer>
                <div className='container-sidebar-register'>
                    <div className="sidebar-options">
                        {localStorage.getItem('profile') === 'administrador' && (
                            <div className="menu_stages">
                                <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                                <a  href="dados-pessoais">Dados Pessoais</a>
                                <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                                <a className={page === 'etapas' ? 'selected' : ''} href="etapas">Etapas</a>
                                <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                                <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados </a>
                                <a className={page === 'simulacao' ? 'selected' : ''} href="simulacao">Simulação</a>
                                <a className={page === 'usuarios' ? 'selected' : ''} href="usuarios">Usuários</a>
                            </div>
                        )}
                        {localStorage.getItem('profile') === 'auditor' && (
                            <div className="menu_stages">
                                <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                                <a  href="dados-pessoais">Dados Pessoais</a>
                                <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                                <a className={page === 'etapas' ? 'selected' : ''} href="etapas">Etapas</a>
                                <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                                <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados </a>
                            </div>
                        )}
                        {localStorage.getItem('profile') === 'camara' && (
                            <div className="menu_stages">
                                <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                                <a  href="dados-pessoais">Dados Pessoais</a>
                                <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                                <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                                <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados </a>
                            </div>
                        )}
                        <ColProfile />
                    </div>
                </div>
                <section className='section-options'>
                    <div className='col-title-image-options'>
                        <img
                            src="/amz.png"
                            alt="Imagem de cadastro"
                            className="register-image"
                        />
                        <h2>{title}</h2>
                        <hr className='divider-title' />
                    </div>
                    <div className='col-options'>
                        <div className='row-options'>
                            <Bar link={href1 || ''} title={nameButton1 || ''} />
                            <Bar link={href2 || ''} title={nameButton2 || ''} />
                            {nameButton4 !== undefined && (
                                <Bar link={href3 || ''} title={nameButton3 || ''} />
                            )}
                        </div>
                        {nameButton4 !== undefined && (
                            <div className='row-options'>
                                <Bar link={href4 || ''} title={nameButton4 || ''} />
                                <Bar link={href5 || ''} title={nameButton5 || ''} />
                                <Bar link={href6 || ''} title={nameButton6 || ''} />
                            </div>
                        )}
                    </div>
                </section>
            </div> 
        </div>
    );
}