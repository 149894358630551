import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar/sidebar';
import './stages.css'
import Navbar from '../../components/Navbar/navbar' 
import ModalRegister from '../../components/ModalRegister/modalRegister';
import CardStages from '../../components/CardStages/cardStages';
import Loading from '../../components/Loading/loading';
import { stages, categories } from '../../api/categoriesStages';
import { stamps } from '../../api/stamps';
import Drawer from '@mui/material/Drawer';

export default function StagesList() {
    const [stagesData, setStagesData] = useState<any>([]);
    const [stampsData, setStampsData] = useState<any>([]);
    const [categoriesData, setCategoriesData] = useState<any>([]);
    const [categoriesDataRegisterStampsData, setCategoriesDataRegisterStampsData] = useState<any>([]);
    const [activeModalRegister, setActiveModalRegister] = useState<boolean>(false);
    const [titleRegister, setTitleRegister] = useState<string>('');

    const list_stage = window.location.href.split('/')
    const stage = list_stage[list_stage.length - 1]

    const [cardVisible, setCardVisible] = useState<boolean>(false);
    const [activeLoading, setActiveLoading] = useState<boolean>(true)
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const handleButtonClick = () => {
        setCardVisible(!cardVisible);
    };

    useEffect(() => {
        const fetchData = async () => {
            const responseStages = await stages();
            setStagesData(responseStages);
            if (stagesData !== null || stagesData !== '') {
                setActiveLoading(false)
            }

            const responseCategories = await categories();
            setCategoriesData(responseCategories);

            const responseStamps = await stamps();
            setStampsData(responseStamps);
        }

        fetchData();
        
    }, []);

    const linksStagesData = [];
    const linksStampsData = [];
    const linksCategoriesData = [];

    for (let stag = 0; stag < stagesData.length; stag++) {
        linksStagesData.push({ text: stagesData[stag].stage.toLowerCase(), href: '/tabela-etapas/'+stagesData[stag].id });
    }

    for (let stam = 0; stam < stampsData.length; stam++) {
        linksStampsData.push({ text: stampsData[stam].stamp.toLowerCase(), href: '/tabela-selos/'+stampsData[stam].id });
    }

    for (let cate = 0; cate < categoriesData.length; cate++) {
        linksCategoriesData.push({ text: categoriesData[cate].categorie.toLowerCase(), href: '/tabela-categorias/'+categoriesData[cate].id });
    }

    const divideColumns = (array: any, tamColumn: number) => {
        const columns = [];
        for (let i = 0; i < array.length; i += tamColumn) {
            columns.push(array.slice(i, i + tamColumn));
        }
        return columns;
    };

    const columnsLinksStagesData = divideColumns(linksStagesData, 2);
    const columnsLinksStampsData = divideColumns(linksStampsData, 2);
    const columnsLinksCategoriesData = divideColumns(linksCategoriesData, 2);

    const handleActiveRegisterStage = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(!activeModalRegister);
        setTitleRegister('NOVA ETAPA');
        setCategoriesDataRegisterStampsData([]);
    }

    const handleActiveRegisterCategorie = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(false);
        setActiveModalRegister(true);
        setTitleRegister('NOVA CATEGORIA');
        setCategoriesDataRegisterStampsData([]);
    }

    const handleActiveRegisterStampsData = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalRegister(false);
        setActiveModalRegister(true);
        setTitleRegister('NOVO SELO');
        setCategoriesDataRegisterStampsData(categoriesData);
    }

    return (
        <div className='stages'>
            <Loading activeProps={activeLoading}/>
            <ModalRegister display={activeModalRegister} title={titleRegister} categories={categoriesDataRegisterStampsData} open={(open: any) => setActiveModalRegister(open)} />
            <Navbar title="Sistema AMZ" user={true} search={true} menu={toggleDrawer(true)} />
            <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                <Sidebar page={stage} groupProfile={localStorage.getItem('profile') || ''} />
            </Drawer>
            <div className='container-sidebar-register'>
                <Sidebar page={stage} groupProfile={localStorage.getItem('profile') || ''} />
            </div>
            <section className='section-stages'>
                <CardStages title="Selos" columnsLinks={columnsLinksStampsData} width='800px' nameButton="criar novo selo" activeModal={handleActiveRegisterStampsData} />
                <CardStages title="Categorias Específicas" columnsLinks={columnsLinksCategoriesData} width='600px' nameButton="criar nova categoria" activeModal={handleActiveRegisterCategorie} />
                <CardStages title="Etapas" columnsLinks={columnsLinksStagesData} width='785px' nameButton="criar nova etapa" activeModal={handleActiveRegisterStage}/>
            </section>
        </div>
    );
}