// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.col-profile button {
  width: 70px;
  margin: 0;
}

.button-logout {
    width: auto;
    background-color: #006633;
    color: #fff;
    height: 30px;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
}

.col-message-approve {
  width: 1200px !important;
  height: 500px !important;
  z-index: 999;
  display: flex;
  justify-content: center;
  margin-left: 1200px;
}

.p-npca {
  margin-top: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ColProfile/colProfile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,SAAS;AACX;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;EACE,wBAAwB;EACxB,wBAAwB;EACxB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".col-profile {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 40px;\n}\n\n.col-profile button {\n  width: 70px;\n  margin: 0;\n}\n\n.button-logout {\n    width: auto;\n    background-color: #006633;\n    color: #fff;\n    height: 30px;\n    border: none;\n    border-radius: 20px;\n    font-size: 16px;\n    font-weight: 600;\n    cursor: pointer;\n    margin-top: 10px;\n    margin-bottom: 20px;\n}\n\n.col-message-approve {\n  width: 1200px !important;\n  height: 500px !important;\n  z-index: 999;\n  display: flex;\n  justify-content: center;\n  margin-left: 1200px;\n}\n\n.p-npca {\n  margin-top: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
