import './reportProblem.css';
import React, { useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import MessageCancel from '../../components/CardMessage/messageCancel';
import MessageRequest from '../../components/CardMessage/MessageRequest/messageRequest';
import Navbar from '../../components/Navbar/navbar';
import Loading from '../../components/Loading/loading';
import { sendReport } from '../../api/report';
import { registerUserLogs } from '../../api/userLogs';
import Drawer from '@mui/material/Drawer';
import ColProfile from '../../components/ColProfile/colProfile';

export default function ReportProblem() {
    const { email } = useParams();
    const [textProblem, setTextProblem] = useState<string>('');
    const [errorProblem, setErrorProblem] = useState<string | null>(null);
    const [activeMessage, setActiveMessage] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<boolean>(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const list_page = window.location.href.split('/');
    const page = list_page[list_page.length - 1];
    const [activeLoading, setActiveLoading] = useState<boolean>(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };
    
    const handleTextProblem = (event: any) => {
        setTextProblem(event.target.value);
        setErrorProblem(null);
    }

    const clearInputsTextProblem = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setTextProblem('');
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (textProblem.trim() === '') {
            setErrorProblem('Campo obrigatório.')
            return;
        } else {
            setErrorProblem('')
        }

        setActiveLoading(true);

        const emailParams = email ? email.toString() : ''

        const responseReport: any = await sendReport(emailParams, textProblem);
        
        if (responseReport.status === 200) {
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('E-mail enviado com sucesso.')
            registerUserLogs("Reportou um problema.");
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique o texto e tente novamente.')
        }
    }

    const classNameCurrentProblem = errorProblem ? 'textarea-report-error' : 'textarea-report';
    const [activeEdit, setActiveEdit] = useState(false);

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveMessage(!activeMessage);
        setReloadMessage(false)
    }

    const handleActiveEdit = (event: any) => {
        event.preventDefault();
        setActiveEdit(true);
    }

    return (
        <div className='register-col'>
            <Loading activeProps={activeLoading} />
            <Navbar title="Sistema AMZ" user={false} menu={toggleDrawer(true)} />
            <section className='section-home'>
                <div className='container-sidebar'>
                    <div className="sidebar-personal-data">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'dados-pessoais' && !activeEdit ? 'selected' : ''} href="dados-pessoais">Dados Pessoais</a>
                            <a className={activeEdit ? 'selected' : ''} onClick={handleActiveEdit}>Editar Dados</a>
                            <a className={page === 'alterar-senha' ? 'selected' : ''} href='alterar-senha'>Alterar Senha</a>
                            <a className={page === 'relatar-problema' && !activeEdit ? 'selected' : ''} href='/relatar-problema'>Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </div>
                <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%", width: "250px", overflowY: "hidden!important"}}>
                    <div className="sidebar-home">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'dados-pessoais' && !activeEdit ? 'selected' : ''} href="dados-pessoais">Dados Pessoais</a>
                            <a className={activeEdit ? 'selected' : ''} onClick={handleActiveEdit}>Editar Dados</a>
                            <a className={page === 'alterar-senha' ? 'selected' : ''} href='alterar-senha'>Alterar Senha</a>
                            <a className={page === 'relatar-problema' && !activeEdit ? 'selected' : ''} href='/relatar-problema'>Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </Drawer>
                <section>
                    <MessageCancel activeProps={activeMessage} reloadProps={false} url='/pagina-inicial' />
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                    <div className="register-box">
                        <div className='row-1'>
                            <div className='col-title-sub-title'>
                                <div className='col-title-image'>
                                    <img
                                        src="/amz.png"
                                        alt="Imagem de cadastro"
                                        className="register-image"
                                    />
                                    <h2>Relatar Problema</h2>
                                </div>
                            </div>
                            <button className='button-register-clear' onClick={clearInputsTextProblem}>limpar tudo</button>
                        </div>
                        <form action="" className='form-report-problem' style={{'paddingLeft': '40px'}}>
                            <div className='row-report-textarea' style={{'paddingTop': '30px', 'paddingBottom': '40px'}}>
                                <div className='col-report'>
                                    <div className='row-label'>
                                        <label className='label-register' style={{width: '600px', textAlign: 'center'}}>Relate seu problema:</label>
                                    </div>
                                    <textarea id='message' onChange={handleTextProblem} className={classNameCurrentProblem} value={textProblem} cols={50} rows={8} style={{"resize": "none"}} />
                                    <span>{errorProblem}</span>
                                </div>
                            </div>
                            <div className='row-button-problem'>
                                <button className='button-register' onClick={handleSubmit} style={{'marginRight': '20px', width: '120px'}}>enviar</button>
                            </div>
                        </form>
                    </div>
                </section>
            </section>
        </div>
    )
}