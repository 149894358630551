import './completeResetPassword.css';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MessageRequest from '../../components/CardMessage/MessageRequest/messageRequest';
import { completeResetPassword, checkToken } from '../../api/resetPassword';
import { registerUserLogs } from '../../api/userLogs';

export default function CompleteResetPasswordScreen() {
    const { id, token } = useParams();
    const [inputPassword, setinputPassword] = useState<string>('');
    const [inputConfPassword, setinputConfPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<string | null>(null);
    const [errorConfPassword, setErrorConfPassword] = useState<string | null>(null);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');

    const idString: string = id !== undefined ? id : "";
    const tokenString: string = token !== undefined ? token : "";

    useEffect(() => {
        const fetchData = async () => {
            const checkReponse: any = checkToken(idString, tokenString);

            if (checkReponse.status === 401) {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Token inválido.')
            }
        }
        fetchData();
    }, [idString, tokenString]);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
        setShowConfPassword((prevShowConfPassword) => !prevShowConfPassword);
    };

    const handleInputPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputPassword(event.target.value);
        setErrorPassword(null);
    }

    const handleInputConfPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setinputConfPassword(event.target.value);
        setErrorConfPassword(null);
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputPassword.trim() === '') {
            setErrorPassword('Campo obrigatório.')
            return;
        } else {
            setErrorPassword('')
        }

        if (inputConfPassword.trim() === '') {
            setErrorConfPassword('Campo obrigatório.')
            return;
        } else if (inputConfPassword !== inputPassword) {
            setErrorConfPassword('Senhas não correspondem.')
            return;
        } else {
            setErrorConfPassword('')
        }

        const editPassword: any = await completeResetPassword(idString, tokenString, inputPassword, inputConfPassword);
        if (editPassword.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Senha alterada com sucesso.')
            registerUserLogs("Alterou sua senha.");
        } else {
            if (editPassword.status === 401) {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Token inválido.')
            } else {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Tente novamente mais tarde.')
            }
        }
    }

    const classNamePassword = errorPassword ? 'input-error' : 'input';
    const classNameConfPassword = errorConfPassword ? 'input-error' : 'input';

    return (
        <div className="reset-screen">
            <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            <div className="reset-box">
                <div className='col-1'>
                <img
                    src="/login-image.png"
                    alt="Imagem de login"
                    className="reset-image"
                />
                </div>
                <div className="vertical-line"></div> {/* Elemento <div> com borda esquerda */}
                <div className='col-2'>
                <h2>NOVA SENHA</h2>
                <form>
                    <label>senha</label>
                    <input type={showPassword ? 'text' : 'password'} id='password' onChange={handleInputPassword} className={classNamePassword} />
                    <span>{errorPassword}</span>
                    <label>confirmar senha</label>
                    <input type={showConfPassword ? 'text' : 'password'} id='conf-password' onChange={handleInputConfPassword} className={classNameConfPassword} />
                    <span>{errorConfPassword}</span>
                    <div className="form-checkbox" id="form_checkbox">
                        <label>
                            <input type="checkbox" id="view_password" onChange={togglePasswordVisibility}  /> Mostrar senhas
                        </label>
                    </div>
                    <div className='div-button'>
                    <button onClick={handleSubmit}>enviar</button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
}
