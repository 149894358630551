import jsPDF from "jspdf";
import "./relatory.css"
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/navbar";
import { cnpjUser } from "../../api/cnpjUsers";
import { filesRelatorySpreadsheet, filterProjectsApproveds, qrcodeRelatory } from "../../api/spreadsheets";
import { dataCompany } from "../../api/dataCompany";
import axios from "axios";
import Loading from "../../components/Loading/loading";
import MessageRequest from "../../components/CardMessage/MessageRequest/messageRequest";
import { registerReport, filtredReport, editReport, registerAccomplishedsTerms } from "../../api/reports_client";
import FileUploadArea from "../../components/FileUploadArea/fileUploadArea";
import { InputAdornment } from "@mui/material";
import { registerUserLogs } from "../../api/userLogs";
import { PDFDocument } from 'pdf-lib';

const Relatory = () => {
    const { id } = useParams();
    const [inputName, setinputName] = useState<string>('');
    const [inputAddress, setinputAddress] = useState<string>('');
    const [inputCep, setinputCep] = useState<string>('');
    const [inputCityUf, setinputCityUf] = useState<string>('');
    const [inputEdiction1, setinputEdiction1] = useState<string>('');
    const [inputEdiction2, setinputEdiction2] = useState<string>('');
    const [inputEdiction3, setinputEdiction3] = useState<string>('');
    const [inputReview1, setinputReview1] = useState<string>('');
    const [inputReview2, setinputReview2] = useState<string>('');
    const [inputReview3, setinputReview3] = useState<string>('');
    const [inputLayout, setinputLayout] = useState<string>('');
    const [inputCoordination1, setinputCoordination1] = useState<string>('');
    const [inputCoordination2, setinputCoordination2] = useState<string>('');
    const [inputCoordination3, setinputCoordination3] = useState<string>('');
    const [inputPresident, setinputPresident] = useState<string>('');
    const [inputStakeholder, setinputStakeholder] = useState<string>('');
    const [inputGoal, setinputGoal] = useState<string>('');
    const [inputMethodology, setinputMethodology] = useState<string>('');
    const [inputCompany, setinputCompany] = useState<string>('');
    const [inputLogo, setinputLogo] = useState<string>('');
    const [dayStart, setDayStart] = useState<string>('');
    const [dayEnd, setDayEnd] = useState<string>('');
    const [inputMonth, setInputMonth] = useState<string>('');
    const [inputYear, setInputYear] = useState<string>('');
    const [noteAudictionStart, setNoteAudictionStart] = useState<string>('');
    const [noteAudictionEnd, setNoteAudictionEnd] = useState<string>('');
    const [accomplished, setAccomplished] = useState<any[]>([]);
    const [term, setTerm] = useState<any[]>([]);
    const [inputNameRelatory, setInputNameRelatory] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    const [projects, setProjects] = useState([]);
    const [userId, setUserId] = useState('');
    const [service, setService] = useState(0);
    const [nameRelatory, setNameRelatory] = useState('');
    const iframeRef: any = useRef(null);
    const [spreadsheets, setSpreadsheets] = useState([]);
    const [activeLoading, setActiveLoading] = useState(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const [bestSpreadsheets, setBestSpreadsheets] = useState<any[]>([]);
    const [nameFile, setNameFile] = useState<string>('');
    const [fileUpload, setFileUpload] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState('');
    const [finalConsiderations, setFinalConsiderations] = useState('');
    const [filesRelatory, setFilesRelatory] = useState([]);
    const [urlQrCode, setUrlQrCode] = useState('');
    const paragraphs = inputCompany.split('/n')

    const handleFileSelect = (file: any) => {
        setNameFile(file.name);
        setFileUpload(file);

        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string') {
                setSelectedFile(result);
            } 
        };
        reader.readAsDataURL(file);
    };

    const loadPDF = (linkFile: string) => {
        if (iframeRef.current) {
            iframeRef.current.src = linkFile;

            return iframeRef
        }
    };

    const typeImage = (nameImage: string) => {
        const detailsName = String(nameImage).split('.')
        
        return detailsName[1]
    }

    const fetchFiles = async (id: number) => {
        const filesAudit = await filesRelatorySpreadsheet(id);
        setFilesRelatory(filesAudit);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setActiveLoading(false);
                setActiveLoading(true);
                const responseReport = await filtredReport(id);
                setUserId(String(responseReport.user_id));
                fetchFiles(parseInt(responseReport.user_id));
                const companyDataRequest = await dataCompany(String(responseReport.user_id));
                setInputNameRelatory(responseReport.title && responseReport.title);
                setSelectedFile((responseReport.image) ? responseReport.image.link : '');
                setinputEdiction1(responseReport.ediction1 ? responseReport.ediction1 : '');
                setinputEdiction2((responseReport.ediction2) ? responseReport.ediction2 : '');
                setinputEdiction3(responseReport.ediction3 ? responseReport.ediction3 : '');
                setinputReview1(responseReport.review1 ? responseReport.review1 : '');
                setinputReview2(responseReport.review2 ? responseReport.review2 : '');
                setinputReview3(responseReport.review3 ? responseReport.review3 : '');
                setinputLayout(responseReport.layout ? responseReport.layout : '');
                setinputCoordination1(responseReport.coordination1 ? responseReport.coordination1 : '');
                setinputCoordination2(responseReport.coordination2 ? responseReport.coordination2 : '');
                setinputCoordination3(responseReport.coordination3 ? responseReport.coordination3 : '');
                setinputPresident(responseReport.president ? responseReport.president : '');
                setinputStakeholder(responseReport.techlead ? responseReport.techlead : '');
                setinputGoal(responseReport.goal ? responseReport.goal : '');
                setinputMethodology(responseReport.methodology ? responseReport.methodology : '');
                setDayStart(responseReport.start_date ? responseReport.start_date : '');
                setDayEnd(responseReport.end_date ? responseReport.end_date : '');
                setInputMonth(responseReport.month ? responseReport.month : '');
                setInputYear(responseReport.year ? responseReport.year : '');
                setNoteAudictionStart(responseReport.start_note ? responseReport.start_note : '');
                setNoteAudictionEnd(responseReport.end_note ? responseReport.end_note : '');
                setAccomplished(responseReport.accomplished_relatory ? responseReport.accomplished_relatory : []);
                setTerm(responseReport.term_relatory ? responseReport.term_relatory : []);
                setFinalConsiderations((responseReport.final_considerations) ? responseReport.final_considerations : '');
                setinputName(companyDataRequest.user.company || '');
                setinputCompany(companyDataRequest.user.description_company || '');
                setinputLogo(companyDataRequest.user.image.link || '')
                setinputAddress(companyDataRequest.user.address_cep_address || '');
                setinputCep(companyDataRequest.user.cep_cep_address || '');
                setinputCityUf(`${companyDataRequest.user.city_cep_address} - ${companyDataRequest.user.uf_cep_address} - ${companyDataRequest.user.country_cep_address}` || '');
                setSpreadsheets(companyDataRequest.spreadsheets[0] || []);
                setBestSpreadsheets(companyDataRequest.best_spreadsheets ? companyDataRequest.best_spreadsheets : []);
            }
            setActiveLoading(false)
        }
        
        fetchData();
    }, [id])

    useEffect(() => {
        const fetchData = async () => {
            const projectsDataRequest = await filterProjectsApproveds(true);
            setProjects(projectsDataRequest);
        }

        fetchData()
    }, []);

    const handleConvertToPdf = async () => {
        setGenerating(true);
        setActiveLoading(true);
        const content: any = document.getElementById('content-relatory');
        const pdf = new jsPDF('p', 'px', 'a4');

        await new Promise((resolve, reject) => {
            pdf.html(content, {
                callback: () => {
                    resolve(undefined);
                },
            });
        });

        const pdfBytes = pdf.output('arraybuffer');
        const pdfDoc = await PDFDocument.create();
        const generatedPdf = await PDFDocument.load(pdfBytes);
        const generatedPdfPage = await pdfDoc.copyPages(generatedPdf, generatedPdf.getPageIndices());
        generatedPdfPage.forEach(page => pdfDoc.addPage(page));
        
        if (filesRelatory.length > 0) {
            for (var file of filesRelatory) {
                var detailFile = String(file['name']).split('.');

                if (detailFile[detailFile.length -1] === 'pdf') {
                    const otherPdfBytes = await fetch(file['link']).then(res => res.arrayBuffer());
                    const otherPdf = await PDFDocument.load(otherPdfBytes);
                    const otherPdfPages = await pdfDoc.copyPages(otherPdf, otherPdf.getPageIndices());
                    otherPdfPages.forEach(page => pdfDoc.addPage(page));
                }
            }
        }

        const mergedPdfBytes = await pdfDoc.save({ 
            useObjectStreams: true, 
            updateFieldAppearances: false
        });

        const formData = new FormData();
        formData.append('file', new Blob([mergedPdfBytes], { type: 'application/pdf' }), inputNameRelatory);

        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = inputNameRelatory;

        if (urlQrCode === '') {
            const qrCode = await qrcodeRelatory(formData);
            setUrlQrCode(qrCode.link);
        } else {
            setUrlQrCode(urlQrCode);
        }

        link.click();

        setGenerating(false);
        setActiveLoading(false);
        registerUserLogs("Baixou relatório.");
    }

    const handleGenerateRelatory = async () => {
        setActiveLoading(true);
        try{
            const formData = new FormData();
            if (fileUpload !== null) {
                formData.append('image', fileUpload);
            }
            formData.append('title', inputNameRelatory);
            formData.append('user_id', userId);
            formData.append('ediction1', inputEdiction1);
            formData.append('ediction2', inputEdiction2);
            formData.append('ediction3', inputEdiction3);
            formData.append('review1', inputReview1);
            formData.append('review2', inputReview2);
            formData.append('review3', inputReview3);
            formData.append('layout', inputLayout);
            formData.append('coordination1', inputCoordination1);
            formData.append('coordination2', inputCoordination2);
            formData.append('coordination3', inputCoordination3);
            formData.append('president', inputPresident);
            formData.append('techlead', inputStakeholder);
            formData.append('goal', inputGoal);
            formData.append('methodology', inputMethodology);
            formData.append('start_date', dayStart);
            formData.append('end_date', dayEnd);
            formData.append('month', inputMonth);
            formData.append('year', inputYear);
            formData.append('start_note', noteAudictionStart);
            formData.append('end_note', noteAudictionEnd);
            formData.append('final_considerations', finalConsiderations);

            const responseRelatory: any = await registerReport(
                formData
            );
                
            if (responseRelatory.status === 201) {
                registerAccomplishedsTerms(inputNameRelatory, accomplished, term);
                setActiveLoading(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Salvo!');
                setMessageRequest('Relatório gerado com sucesso.');
                setUrlQrCode('');
            } else {
                setActiveLoading(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro!');
                setMessageRequest('Título, capa e cliente são obrigatórios.');
            }
        } catch(e) {
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Título, capa e cliente são obrigatórios.');
        }
    }

    const handleEditRelatory = async () => {
        const formData = new FormData();
        if (fileUpload !== null) {
            formData.append('image', fileUpload);
        }
        formData.append('id', id ? id : '');
        formData.append('title', inputNameRelatory);
        formData.append('user_id', userId);
        formData.append('ediction1', inputEdiction1);
        formData.append('ediction2', inputEdiction2);
        formData.append('ediction3', inputEdiction3);
        formData.append('review1', inputReview1);
        formData.append('review2', inputReview2);
        formData.append('review3', inputReview3);
        formData.append('layout', inputLayout);
        formData.append('coordination1', inputCoordination1);
        formData.append('coordination2', inputCoordination2);
        formData.append('coordination3', inputCoordination3);
        formData.append('president', inputPresident);
        formData.append('techlead', inputStakeholder);
        formData.append('goal', inputGoal);
        formData.append('methodology', inputMethodology);
        formData.append('start_date', dayStart);
        formData.append('end_date', dayEnd);
        formData.append('month', inputMonth);
        formData.append('year', inputYear);
        formData.append('start_note', noteAudictionStart);
        formData.append('end_note', noteAudictionEnd);
        formData.append('final_considerations', finalConsiderations);

        const responseRelatory: any = await editReport(
            formData
        );
            
        if (responseRelatory.status === 200) {
            registerAccomplishedsTerms(inputNameRelatory, accomplished, term);
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Salvo!');
            setMessageRequest('Relatório salvo com sucesso.');
            registerUserLogs("Criou/editou relatório.");
            setUrlQrCode('');
        } else {
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Verifique os dados e tente novamente.');
        }
    }

    const handleCancelPdf = () => {
        window.location.href = "/relatorios"
    }

    const handleUserId = async (event: any) => {
        setActiveLoading(true)
        setUserId(event.target.value);
        fetchFiles(parseInt(event.target.value));
        const companyDataRequest = await dataCompany(event.target.value);
        setinputName(companyDataRequest.user.company || '');
        setinputCompany(companyDataRequest.user.description_company || '');
        setinputLogo(companyDataRequest.user.image.link || '')
        setinputAddress(companyDataRequest.user.address_cep_address || '');
        setinputCep(companyDataRequest.user.cep_cep_address || '');
        setinputCityUf(`${companyDataRequest.user.city_cep_address} - ${companyDataRequest.user.uf_cep_address} - ${companyDataRequest.user.country_cep_address}` || '');
        setSpreadsheets(companyDataRequest.spreadsheets[0] || []);   
        setBestSpreadsheets(companyDataRequest.best_spreadsheets ? companyDataRequest.best_spreadsheets : []);
        setActiveLoading(false)
    }

    useEffect(() => {
        setSpreadsheets(spreadsheets);
    }, [spreadsheets])

    const handleInputName = (event: any) => {
        setNameRelatory(event.target.value);
    }

    const handleInputAddress = (event: any) => {
        setinputAddress(event.target.value);
    }

    const handleInputCep = (event: any) => {
        setinputCep(event.target.value);
    }

    const handleInputCityUf = (event: any) => {
        setinputCityUf(event.target.value);
    }

    const handleInputEdiction1 = (event: any) => {
        setinputEdiction1(event.target.value);
    }

    const handleInputEdiction2 = (event: any) => {
        setinputEdiction2(event.target.value);
    }

    const handleInputEdiction3 = (event: any) => {
        setinputEdiction3(event.target.value);
    }

    const handleInputReview1 = (event: any) => {
        setinputReview1(event.target.value);
    }

    const handleInputReview2 = (event: any) => {
        setinputReview2(event.target.value);
    }

    const handleInputReview3 = (event: any) => {
        setinputReview3(event.target.value);
    }

    const handleInputLayout = (event: any) => {
        setinputLayout(event.target.value);
    }

    const handleInputCoordination1 = (event: any) => {
        setinputCoordination1(event.target.value);
    }
    
    const handleInputCoordination2 = (event: any) => {
        setinputCoordination2(event.target.value);
    }

    const handleInputCoordination3 = (event: any) => {
        setinputCoordination3(event.target.value);
    }

    const handleInputPresident = (event: any) => {
        setinputPresident(event.target.value);
    }

    const handleInputStakeholder = (event: any) => {
        setinputStakeholder(event.target.value);
    }

    const handleInputGoal = (event: any) => {
        setinputGoal(event.target.value);
    }

    const handleInputMethodology = (event: any) => {
        setinputMethodology(event.target.value);
    }

    const handleInputCompany = (event: any) => {
        setinputCompany(event.target.value);
    }

    const handleFinalConsiderations = (event: any) => {
        setFinalConsiderations(event.target.value);
    }

    const handleDayStart = (event: any) => {
        setDayStart(event.target.value);
    }

    const handleDayEnd = (event: any) => {
        setDayEnd(event.target.value);
    }

    const handleInputMonth = (event: any) => {
        setInputMonth(event.target.value);
    }

    const handleInputYear = (event: any) => {
        setInputYear(event.target.value);
    }

    const handleNoteAudictionStart = (event: any) => {
        setNoteAudictionStart(event.target.value);
    }

    const handleNoteAudictionEnd = (event: any) => {
        setNoteAudictionEnd(event.target.value);
    }

    const handleAccomplished = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        setAccomplished(prevState => {
            const updatedAccomplished = [...prevState]; 
            updatedAccomplished[index] = value;
            return updatedAccomplished;
        });
    }

    const handleTerm = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        setTerm(prevState => {
            const updatedTerm = [...prevState]; 
            updatedTerm[index] = value;
            return updatedTerm;
        });
    }

    const handleInputNameRelatory = (event: any) => {
        setInputNameRelatory(event.target.value);
    }

    return (
        <div className="background-relatory" style={{ margin: 0, padding: 0 }}>
            <Navbar title="Sistema AMZ" user={true} buttonSave={true} functionSave={id ? handleEditRelatory : handleGenerateRelatory} buttonDownload={true} functionDownload={handleConvertToPdf} />
            <div className="container-relatory">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="container-title">
                        <img
                            src="/amz.png"
                            alt="Imagem de cadastro"
                            className="amz-relatory-image"
                        />
                        <h3 className="title-relatory">* Relatório</h3>
                        <input type="text" onChange={handleInputNameRelatory} value={inputNameRelatory} style={{ width: "260px", marginLeft: "20px" }}/>
                    </div>
                    <h3 className="title-relatory">* Capa</h3>
                    <FileUploadArea onFileSelect={handleFileSelect} nameFile={nameFile} />
                </div>
            </div>
            <div style={{ width: "100%", height: "100%", display: activeLoading ? "flex" : "none", position: "fixed", zIndex: "999" }}>
                <Loading activeProps={activeLoading} />
            </div>
            <div style={{ marginTop: "380px" }}>
                <div style={{ height: "100%", display: activeMessageRequest ? "flex" : "none", position: "fixed", zIndex: "999" }}>
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                </div>
                <div id="content-relatory" className="content-relatory">
                    <main className="cover">
                        {!nameFile && selectedFile ? (<img className="img-cover" src={`data:image/jpeg;base64,${selectedFile}`} />) : (<img className="img-cover" src={`${selectedFile}`} />)}
                        <div className="area-body-cover">
                            <div className="area-logo-relatory">
                                {urlQrCode === '' ? (
                                    <div></div>
                                ) : (
                                    <img src={`data:image/jpeg;base64,${urlQrCode}`} alt="" style={{ width: "80px", height: "80px", marginLeft:"10px", marginTop: "10px" }} />
                                )}
                                <img src="/images_relatory/icon_amz_relatory.png" alt="icon_amz_relatory" style={{ width: "80px", height: "130px", marginRight:"25px" }} />
                            </div>
                            <div className="area-title">
                                <div className="col-title-area" style={{ height: generating ? "40px" : "80px", paddingTop: generating ? 0 : "30px", paddingBottom: generating ? "80px" : "30px" }}>
                                    <h2 className="title-cover">
                                        RELATÓRIO DE AUDITORIA 
                                    </h2>
                                    <h2 className="title-cover">
                                        E CERTIFICAÇÃO AMBIENTAL
                                    </h2>
                                    {generating ? (<h3 className="subtitle-cover" style={{ marginBottom: "80px" }}>
                                        {inputName}
                                    </h3>) : (
                                        <select className="select-register" style={{ width: "260px" }} value={userId} onChange={handleUserId}>
                                            <option value="">Selecione...</option>
                                            {projects.map((project: any, index: number) => (
                                                <option key={index} value={project.stakeholder}>
                                                    {project.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </div>
                        </div>
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div className="top-cover-against">
                            <img src="/images_relatory/amz_relatory.png" alt="amz_relatory" style={{ width: "80px", height: "100px" }} />
                            <div className="area-title-page2" style={{ margin: 0 }}>
                                <h2 className="title-cover">
                                    RELATÓRIO DE AUDITORIA 
                                </h2>
                                <h2 className="title-cover">
                                    E CERTIFICAÇÃO AMBIENTAL
                                </h2>
                                <h3 className="subtitle-cover">
                                    {inputName}
                                </h3>
                            </div>
                        </div>
                        <div className="area-stakeholders">
                            <div className="row-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right" }}>
                                    Edição
                                </h4>
                                <b>|</b>
                                {generating ? (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p>
                                            {inputEdiction1}
                                        </p>
                                        <p>
                                            {inputEdiction2}
                                        </p>
                                        <p>
                                            {inputEdiction3}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <input type="text" onChange={handleInputEdiction1} value={inputEdiction1} style={{ width: "260px" }}/>
                                        <input type="text" onChange={handleInputEdiction2} value={inputEdiction2} style={{ width: "260px" }}/>
                                        <input type="text" onChange={handleInputEdiction3} value={inputEdiction3} style={{ width: "260px" }}/>
                                    </div>
                                )}
                            </div>
                            <div className="row-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right" }}>
                                    Revisão
                                </h4>
                                <b>|</b>
                                {generating ? (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p>
                                            {inputReview1}
                                        </p>
                                        <p>
                                            {inputReview2}
                                        </p>
                                        <p>
                                            {inputReview3}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <input type="text" onChange={handleInputReview1} value={inputReview1} style={{ width: "260px" }}/>
                                        <input type="text" onChange={handleInputReview2} value={inputReview2} style={{ width: "260px" }}/>
                                        <input type="text" onChange={handleInputReview3} value={inputReview3} style={{ width: "260px" }}/>
                                    </div>
                                )}
                            </div>
                            <div className="row-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right" }}>
                                    Diagramação
                                </h4>
                                <b>|</b>
                                {generating ? (
                                    <p>
                                        {inputLayout}
                                    </p>
                                ) : (
                                    <input type="text" onChange={handleInputLayout} value={inputLayout} style={{ width: "260px" }}/>
                                )}
                            </div>
                            <div className="row-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right" }}>
                                    Coordenação
                                </h4>
                                <b>|</b>
                                {generating ? (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p>
                                            {inputCoordination1}
                                            
                                        </p>
                                        <p>
                                            {inputCoordination2}
                                        </p>
                                        <p>
                                            {inputCoordination3}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <input type="text" onChange={handleInputCoordination1} value={inputCoordination1} style={{ width: "260px" }}/>
                                        <input type="text" onChange={handleInputCoordination2} value={inputCoordination2} style={{ width: "260px" }}/>
                                        <input type="text" onChange={handleInputCoordination3} value={inputCoordination3} style={{ width: "260px" }}/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src="/images_relatory/borda.png" alt="borda" />
                        <div style={{ display: "flex", flexDirection: "row", width: "367px" }}>
                            <div className="data-company">
                                <img src="/images_relatory/sumario.png" alt="sumario" style={{ height: "25px", marginTop: "25px" }} />
                                <div className="company">
                                <img src="/images_relatory/sumario_relatorio.png" alt="sumario_relatorio" style={{ width: "100%", marginTop: "80px" }} />
                                </div>
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src="/images_relatory/borda.png" alt="borda" />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="data-company" style={{ paddingRight: "20px", width: "280px" }}>
                                <img src="/images_relatory/apresentacao.png" alt="apresentacao" style={{ height: "25px" }} />
                                <div className="company">
                                    {generating ? (
                                        paragraphs.length && paragraphs.map((value) => (
                                            <p style={{ textAlign: "justify", fontSize: "12px" }}>{value.replace("/n", " ")}</p>
                                        ))
                                    ) : (
                                        <textarea onChange={handleInputCompany} value={inputCompany} cols={50} rows={8} style={{"resize": "none", width: "100%", height: "465px"}}   />
                                    )}
                                </div>
                            </div>
                            <div style={{ width: "95px", height: "auto" }}>
                                {inputLogo === '' ? (
                                    <div></div>
                                ) : (
                                    <img src={`data:image/jpeg;base64,${inputLogo}`} alt="foto" style={{ maxWidth: "110px", height: "auto!important" }} />
                                )}
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src="/images_relatory/borda.png" alt="borda" />
                        <div className="data-company" style={{ height: "240px", width: "376px", justifyContent: "flex-start" }}>
                            <img src="/images_relatory/amazon.png" alt="empresa" style={{ height: "25px" }} />
                            <div className="company" style={{ padding: "0", width: "376px" }}>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    <h5 className="title-data-company">
                                        SISTEMA DE CERTIFICAÇÃO AMAZON 
                                    </h5>
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                <h5 className="title-data-company">
                                        Av. Perimetral, 362 - Marco | PCT Guamá - Espaço Empreendedor 
                                    </h5>
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    <h5 className="title-data-company">
                                        66077-830 
                                    </h5>
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    <h5 className="title-data-company">
                                        Belém - PA - Brasil
                                    </h5>
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    <h5 className="title-data-company">
                                        +55 91 99346-8595
                                    </h5>
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    <h5 className="title-data-company">
                                        contato@amzbrazil.com.br
                                    </h5>
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    <h5 className="title-data-company">
                                        www.amzbrazil.com.br
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="data-company" style={{ height: "150px", width: "376px" }}>
                            <img src="/images_relatory/organizacao.png" alt="empresa" style={{ height: "25px" }} />
                            <div className="company" style={{ padding: "0", width: "100%" }}>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    <h5 className="title-data-company">
                                        {inputName}
                                    </h5>
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    {generating ? (<h5 className="title-data-company">
                                        {inputAddress}
                                    </h5>) : (
                                        <input placeholder="Av. Perimetral, 362 - Marco" type="text" onChange={handleInputAddress} value={inputAddress} style={{ width: "376px", margin: "0 0 10px 0" }}/>
                                    )}
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    {generating ? (<h5 className="title-data-company">
                                        {inputCep}
                                    </h5>) : 
                                        <input placeholder="Cep: XXXXX-XXX" type="text" onChange={handleInputCep} value={inputCep} style={{ width: "376px", margin: "0 0 10px 0" }}/>
                                    }
                                </div>
                                <div className="row-data-company" style={{ width: "376px" }}>
                                    {generating ? (<h5 className="title-data-company">
                                        {inputCityUf}
                                    </h5>) : (
                                        <input placeholder="Belém - PA - Brasil" type="text" onChange={handleInputCityUf} value={inputCityUf} style={{ width: "376px", margin: "0 0 10px 0" }}/>
                                    )}
                                </div>
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src="/images_relatory/borda.png" alt="borda" />
                        <div style={{ display: "flex", flexDirection: "column", width: "367px" }}>
                            <div className="data-company" style={{ height: "110px" }}>
                                <img src="/images_relatory/auditoria.png" alt="auditoria" style={{ height: "20px" }} />
                                <div className="company-stakeholders">
                                    <div className="container-president-company">
                                        <h5 className="president-company">PRESIDENTE</h5>
                                        {generating ? (<p className="president-name" style={{ marginBottom: generating ? "30px" : "0" }}>{inputPresident}</p>) : (
                                            <input type="text" onChange={handleInputPresident} value={inputPresident} style={{ width: "140px", margin: "0" }}/>
                                        )}
                                    </div>
                                    <div className="container-technician-company">
                                        <h5 className="technician-company">RESPONSÁVEL TÉCNICO</h5>
                                        {generating ? (
                                            <p className="technician-name" style={{ marginBottom: generating ? "30px" : "0" }}>{inputStakeholder}</p>
                                        ) : (
                                            <input type="text" onChange={handleInputStakeholder} value={inputStakeholder} style={{ width: "140px", margin: "0" }}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="data-company" style={{ height: "100px" }}>
                                <img src="/images_relatory/objetivo.png" alt="objetivo" style={{ height: "25px" }} />
                                <div className="company">
                                    {generating ? (
                                        <p style={{ textAlign: "justify", fontSize: "12px" }}>{inputGoal} </p>
                                    ) : (
                                        <textarea onChange={handleInputGoal} value={inputGoal} cols={50} rows={8} style={{"resize": "none", width: "360px", height: "60px"}} />
                                    )}
                                </div>
                            </div>
                            <div className="data-company" style={{ height: "100px" }}>
                                <img src="/images_relatory/metodologia.png" alt="metodologia" style={{ height: "25px" }} />
                                <div className="company">
                                    {generating ? (
                                        <p style={{ textAlign: "justify", fontSize: "12px" }}>{inputMethodology} </p>
                                    ) : (
                                        <textarea onChange={handleInputMethodology} value={inputMethodology} cols={50} rows={8} style={{"resize": "none", width: "360px", height: "60px"}}   />
                                    )}
                                </div>
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src="/images_relatory/borda.png" alt="borda" />
                        <div style={{ display: "flex", flexDirection: "column", width: "367px" }}>
                            <div className="data-company" style={{ height: "40px" }}>
                                <img src="/images_relatory/periodo.png" alt="periodo" style={{ height: "40px" }} />
                            </div>
                            <div className="row-data-company" style={{ height: "400px", paddingLeft: "70px", alignItems: "flex-start", justifyContent: "space-between" }}>
                                <div className="col-period" style={{ marginRight: "5px" }}>
                                    {generating ? (
                                        <h5 className="days-audiction">{dayStart} à {dayEnd}</h5>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <input placeholder="Início" type="text" onChange={handleDayStart} value={dayStart} style={{ width: "40px", margin: "0 5px 10px 0" }}/>
                                            <p>à</p>
                                            <input placeholder="Fim" type="text" onChange={handleDayEnd} value={dayEnd} style={{ width: "40px", margin: "0 0 10px 5px" }}/>
                                        </div>
                                    )}
                                    {generating ? (
                                        <p>{inputMonth}</p> 
                                    ) : (
                                        <input placeholder="Mês" type="text" onChange={handleInputMonth} value={inputMonth} style={{ width: "40px", margin: "0 5px 10px 0" }}/>
                                    )}
                                    {generating ? (
                                        <p>{inputYear}</p> 
                                    ) : (
                                        <input placeholder="Ano" type="text" onChange={handleInputYear} value={inputYear} style={{ width: "40px", margin: "0 5px 10px 0" }}/>
                                    )}
                                </div>
                                <div className="col-period">
                                    <div className="row-data-company" style={{ marginBottom: "15px", width: "280px", padding: "0 60px 0 30px" }}>
                                        <div className="col-period" style={{ marginRight: "25px" }}>
                                            <h5 className="day-audiction">{dayStart}</h5>
                                            <p className="month-audiction">de {inputMonth}</p>
                                        </div>
                                        <div className="col-period" style={{ marginLeft: "20px" }}>
                                            <h5 className="day-audiction">{dayEnd}</h5>
                                            <p className="month-audiction">de {inputMonth}</p>
                                        </div>
                                    </div>
                                    <img src="/images_relatory/linha.png" alt="linha" style={{ width: "330px" }} />
                                    <div className="result-audiction">
                                        <div className="container-technician-result" style={{ marginRight: "20px" }}>
                                            {generating ? (
                                                <p className="technician-name" style={{ margin: 0, marginBottom: generating ? "30px" : "0" }}>{noteAudictionStart}</p>
                                            ) : (
                                                <input placeholder="Nota" type="text" onChange={handleNoteAudictionStart} value={noteAudictionStart} style={{ width: "40px", margin: "0 0 10px 5px" }}/>
                                            )}
                                        </div>
                                        <div className="container-technician-result">
                                        {generating ? (
                                                <p className="technician-name" style={{ margin: 0, marginBottom: generating ? "30px" : "0" }}>{noteAudictionStart}</p>
                                            ) : (
                                                <input placeholder="Nota" type="text" onChange={handleNoteAudictionEnd} value={noteAudictionEnd} style={{ width: "40px", margin: "0 0 10px 5px" }}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    {spreadsheets.map((spreadsheet: any, index: number) => (
                        <div key={index}>
                            <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <img src="/images_relatory/borda.png" alt="borda" />
                                <div style={{ display: "flex", flexDirection: "column", width: "367px" }}>
                                    <div className="data-company" style={{ height: "20px" }}>
                                        <img src="/images_relatory/tabela_resultados.png" alt="tabela_resultados" style={{ height: "20px" }} />
                                    </div>
                                    <div className="table-relatory">
                                        <div style={{ paddingBottom: generating ? "10.5px" : "1px" }}>
                                            <img className="stage-spreadsheet" style={{ marginTop: generating ? "27px" : "18px" }} src={`data:image/jpeg;base64,${spreadsheet.stage_id_image.link}`} alt="etapa" />
                                            <p className="data-relatory" style={{ margin: "31px 0 0 100px", width: "250px", maxHeight: "10px", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{spreadsheet.stage_id_stage}</p>
                                            <p className="data-relatory" style={{ margin: "41.5px 0 0 100px", maxWidth: "250px", maxHeight: "10px" }}>{spreadsheet.indicators_id_requirements.length <= 45 ? spreadsheet.indicators_id_requirements : spreadsheet.indicators_id_requirements.substring(0, 45)+'...'}</p>
                                            <p className="data-relatory" style={{ margin: "51.5px 0 0 100px", width: "250px", height: "38px", textOverflow: "ellipsis", overflow: "hidden" }}>{spreadsheet.indicators_id_indicators}</p>
                                            {generating ? (
                                                <p className="data-relatory" style={{ margin: "106.5px 0 0 5px", width: "345px" }}>{accomplished && accomplished[index]}</p>
                                            ) : (
                                                <input placeholder="Digite..." type="text" onChange={(e) => handleAccomplished(e, index)} value={accomplished[index]} style={{ width: "347px ", height: "8px", position: "absolute", margin: "106.5px 0 0 0", fontSize: "10px" }}/>
                                            )}
                                            <div>
                                                {(spreadsheet.service ===  1 || spreadsheet.service === 2) && (<p className="data-relatory" style={{ margin: "143.5px 0 0 42px", width: "345px" }}>X</p>)}
                                                {spreadsheet.service ===  3 && <p className="data-relatory" style={{ margin: "143.5px 0 0 131px", width: "345px" }}>X</p>}
                                                {spreadsheet.service ===  4 && (<p className="data-relatory" style={{ margin: "143.5px 0 0 219.6px", width: "345px" }}>X</p>)}
                                                {spreadsheet.service ===  5 && (<p className="data-relatory" style={{ margin: "143.5px 0 0 309px", width: "345px" }}>X</p>)}
                                            </div>
                                            <p className="data-relatory" style={{ margin: "164px 0 0 5px", width: "345px", height: "20px", textOverflow: "ellipsis", overflow: "hidden" }}>{spreadsheet.evidence}</p>
                                            <p className="data-relatory" style={{ margin: "186px 0 0 5px", width: "345px", height: "20px", textOverflow: "ellipsis", overflow: "hidden" }}>{spreadsheet.observation}</p>
                                            <p className="data-relatory" style={{ margin: "207px 0 0 5px", width: "345px", height: "20px", textOverflow: "ellipsis", overflow: "hidden" }}>{spreadsheet.action}</p>
                                            {generating ? (
                                                <p className="data-relatory" style={{ margin: "228px 0 0 5px", width: "345px" }}>{term || term[index] ? term[index] : ''}</p>
                                            ) : (
                                                <input placeholder="Digite..." type="text" onChange={(e) => handleTerm(e, index)} value={term || term[index] ? term[index] : ''} style={{ width: "347px ", height: "8px", position: "absolute", margin: "228px 0 0 0", fontSize: "10px" }}/>
                                            )}
                                        </div>
                                        <img src="/images_relatory/tabela.png" alt="tabela" style={{ width: "355px" }} />
                                    </div>
                                    
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", width: "367px" }}>
                                    <div className="data-company" style={{ height: "20px" }}>
                                        <img src="/images_relatory/resultado.png" alt="resultado" style={{ height: "20px" }} />
                                    </div>
                                    <div className="row-data-company" style={{ height: "100px" }}>
                                        <div className="data-company" style={{ height: "100px", marginBottom: generating ? "40px" : "0" }}>
                                            <div className="company">
                                                <p style={{ textAlign: "justify", fontSize: "12px" }}>{spreadsheet.result} </p>
                                            </div>
                                        </div>
                                        <img src="/images_relatory/certifica.png" alt="certifica" style={{ height: "100px", marginRight: "8px" }} />
                                    </div>
                                </div>
                                <img src="/images_relatory/borda.png" alt="borda" />
                            </main>
                            {spreadsheet.images && typeImage(spreadsheet.images[0].name) !== 'pdf' && spreadsheet.images.map((image: any, indexImage: number) => (
                                <main key={indexImage} className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <img src="/images_relatory/borda.png" alt="borda" />
                                    <div style={{ display: "flex", flexDirection: "row", width: "367px" }}>
                                        <div className="data-company">
                                            <img src="/images_relatory/anexos.png" alt="anexos" style={{ height: "25px" }} />
                                            <div className="company">
                                                <img src={`data:image/jpeg;base64,${image.link}`} alt={image.name} style={{ width: "100%", height: "auto", maxHeight: "500px" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <img src="/images_relatory/borda.png" alt="borda" />
                                </main>
                            ))}
                        </div>
                    ))}
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src="/images_relatory/borda.png" alt="borda" />
                        <div style={{ display: "flex", flexDirection: "row", width: "367px" }}>
                            <div className="data-company">
                                <div className="company" style={{ width: "367px" }}>
                                    <thead style={{ width: "100%", maxWidth: "367px", maxHeight: "465px", padding: 0, fontSize: "10px" }}>
                                        <tr style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <th style={{ paddingBottom: generating ?  "20px" : 0 }}>Resultado</th>
                                            <th style={{ paddingRight: "5px", paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>COD</th>
                                            <th style={{ paddingRight: "145px", paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>Indicadores</th>
                                            <th style={{ paddingRight: "5px", paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>Grau</th>
                                        </tr>
                                        <tbody style={{ minHeight: "465px" }}>
                                            {bestSpreadsheets[0] && bestSpreadsheets[0].map((spreadsheet: any, index: number) => (
                                                <tr key={index}>
                                                    <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{spreadsheet.result}</td>
                                                    <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{spreadsheet.indicators_id_cod}</td>
                                                    <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{spreadsheet.indicators_id_indicators}</td>
                                                    <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{spreadsheet.critical_degree}</td>
                                                </tr>
                                            ))}
                                        </tbody> 
                                    </thead>
                                </div>
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    {bestSpreadsheets && bestSpreadsheets.map((spreadsheet: any, index: number) => (
                        <div>
                            {index > 0 && (<main key={index} className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <img src="/images_relatory/borda.png" alt="borda" />
                                <div style={{ display: "flex", flexDirection: "row", width: "367px" }}>
                                    <div className="data-company">
                                        <div className="company" style={{ width: "367px" }}>
                                            <thead style={{ width: "100%", maxWidth: "367px", maxHeight: "465px", padding: 0, fontSize: "10px" }}>
                                                <tbody style={{ maxHeight: "465px" }}>
                                                    {spreadsheet && spreadsheet.map((data: any, indexData: number) => (
                                                        <tr key={indexData}>
                                                            <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{(data) && data.result}</td>
                                                            <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{(data) && data.indicators_id_cod}</td>
                                                            <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{(data) && data.indicators_id_indicators}</td>
                                                            <td style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: generating ?  0 : "20px" }}>{(data) && data.critical_degree}</td>
                                                        </tr>
                                                    ))}
                                                </tbody> 
                                            </thead>
                                        </div>
                                    </div>
                                </div>
                                <img src="/images_relatory/borda.png" alt="borda" />
                            </main>)}
                        </div>
                    ))}
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src="/images_relatory/borda.png" alt="borda" />
                        <div style={{ display: "flex", flexDirection: "row", width: "367px" }}>
                            <div className="data-company">
                                <img src="/images_relatory/consideracoes.png" alt="consideracoes" style={{ height: "25px", marginTop: "25px" }} />
                                <div className="company">
                                    {generating ? (
                                        <p style={{ textAlign: "justify", fontSize: "12px", width: "367px", height: "475px", overflow: "hidden" }}>{finalConsiderations} </p>
                                    ) : (
                                        <textarea onChange={handleFinalConsiderations} value={finalConsiderations} cols={50} rows={8} style={{"resize": "none", width: "100%", height: "465px"}}/>
                                    )}
                                </div>
                            </div>
                        </div>
                        <img src="/images_relatory/borda.png" alt="borda" />
                    </main>
                    {filesRelatory && filesRelatory.map((file: any, index: number) => (
                        <div>
                            {String(file.name).split('.')[String(file.name).split('.').length - 1] !== 'pdf' && String(file.name).split('.')[String(file.name).split('.').length - 1] !== 'docx' && (
                                <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <img src={file.link} alt="anexo" />
                                </main>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="buttons-relatory">
                <button onClick={handleCancelPdf} className="button-relatory">cancelar</button>
                <button onClick={id ? handleEditRelatory : handleGenerateRelatory} className="button-relatory">salvar</button>
            </div>
            <div className="container-relatory" style={{ position: "relative", display: "flex", justifyContent: "center", height: "40px", marginTop: "30px" }}>
                <p>© NPCA</p>
            </div>
        </div>
    );
}

export default Relatory;