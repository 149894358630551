import React from "react";
import ColProfile from "../ColProfile/colProfile";

export default function Sidebar({page, groupProfile}: {page: string, groupProfile: string}) {
    return (
        <div className="sidebar-home">
            {groupProfile === 'administrador' &&
                (<div className="menu_stages">
                    <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                    <a  href="dados-pessoais">Dados Pessoais</a>
                    <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                    <a className={page === 'etapas' ? 'selected' : ''} href="etapas">Etapas</a>
                    <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                    <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados </a>
                    <a className={page === 'simulacao' ? 'selected' : ''} href="simulacao">Simulação</a>
                    <a className={page === 'usuarios' ? 'selected' : ''} href="usuarios">Usuários</a>
                    <a className={page === 'monitoramento' ? 'selected' : ''} href="monitoramento">Monitoramento</a>
                </div>) 
            }
            {groupProfile === 'cliente' && 
                (<div className="menu_stages">
                    <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                    <a  href="dados-pessoais">Dados Pessoais</a>
                    <a className={page === 'simulacao' ? 'selected' : ''} href="simulacao">Simulação</a>
                    <a className={page === 'certificacao' ? 'selected' : ''} href="certificacao">Certificação</a>
                </div>)
            }
            {groupProfile === 'auditor' && 
                (<div className="menu_stages">
                    <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                    <a  href="dados-pessoais">Dados Pessoais</a>
                    <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                    <a className={page === 'etapas' ? 'selected' : ''} href="etapas">Etapas</a>
                    <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                    <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados</a>
                </div>)
            }
            {groupProfile === 'camara' && 
                (<div className="menu_stages">
                    <a className={page === 'pagina-inicial' ? 'selected' : ''} href="pagina-inicial">Página Inicial</a>
                    <a  href="dados-pessoais">Dados Pessoais</a>
                    <a className={page === 'planilhas' ? 'selected' : ''} href="planilhas">Auditoria</a>
                    <a className={page === 'relatorios' ? 'selected' : ''} href="relatorios">Relatórios</a>
                    <a className={page === 'resultados' ? 'selected' : ''} href="resultados">Resultados</a>
                </div>)
            }
            <ColProfile />
        </div>
    );
}