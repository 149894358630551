import './messageRequest.css';
import React, { useState, useEffect } from 'react';

type MessageRequestProps = {
    activeProps: boolean,
    title: string,
    message: string
}

export default function MessageRequest({activeProps, title, message}: MessageRequestProps) {
    const [active, setActive] = useState(activeProps);

    useEffect(() => {
        setActive(activeProps);
    }, [activeProps]);

    const handleOk = () => {
        setActive(false);
    }

    return (
        <div className={active === true ? 'message-request' : 'message-request-none'}>
            <div className='div-request'>
                <h4 className='title'>{title}</h4>
                <p className='message'>{message}</p>
                <div className='col-button-message-request'>
                    <button className='button-message' onClick={handleOk}>ok</button>
                </div>
            </div>
        </div>
    );
}