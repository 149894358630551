import MessageRequest from "../CardMessage/MessageRequest/messageRequest";
import './modalViewStage.css'
import React, { useEffect, useState } from 'react';
import { indicatorsFiltredById } from "../../api/indicators";
import { useParams } from "react-router-dom";
import { registerUserLogs } from "../../api/userLogs";

type ModalViewStageProps = {
    activeModal: boolean, 
    indicator: any, 
    namePage: string
}

export default function ModalViewStage({activeModal, indicator, namePage}: ModalViewStageProps) {
    const { name_table, id } = useParams();
    const [active, setActive] = useState(false);
    const [activeModalView, setActiveModalView] = useState(false);
    const [dataCategories, setDataCategories] = useState('');
    const [inputStage, setinputStage] = useState('');
    const [inputRequirements, setinputRequirements] = useState('');
    const [inputCod, setinputCod] = useState('');
    const [inputIndicator, setinputIndicator] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [indicatorIsActive, setIndicatorIsActive] = useState(true);
    const [idIndicator, setIdIndicator] = useState(true);
    const [dataStages, setDataStages] = useState([]);
    const [activeMessageRequest, setActiveMessageRequest] = useState(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState('');
    const [messageRequest, setMessageRequest] = useState('');

    useEffect(() => {
        setActive(activeModal);
        setActiveModalView(activeModal);

        const fetchData = async () => {
            try {
                const idParams = id ? parseInt(id) : 0
                const indicatorRequest: any = await indicatorsFiltredById(idParams);
                registerUserLogs("Visualizou dado referente ao selo.");
                setIdIndicator(indicatorRequest[0]['id']);
                setinputStage(indicatorRequest[0]['stage_id_stage']);
                setinputRequirements(indicatorRequest[0]['requirements']);
                setinputCod(indicatorRequest[0]['cod']);
                setinputIndicator(indicatorRequest[0]['indicators']);
                setSelectedCategories(indicatorRequest[0]['categories']);
                setIndicatorIsActive(indicatorRequest[0]['is_active']);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        }

        fetchData();
    }, [activeModal, indicator]);

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalView(false);
        setActive(false);
    };
    
    return(
        <div>
            <div className='col-message'>
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            </div>
            <div className='modal-view-indicator'>
                <form className='form-view'>
                    <div className='col-title-image-nav'>
                        <img
                            src="/amz.png"
                            alt="Imagem de cadastro"
                            className="register-image-nav"
                            style={{'marginLeft': '15px'}}
                        />
                        <h3> {namePage} </h3>
                    </div>
                    <hr className='divider-modal-view' />
                    <div className="row-label-p">
                        <div className='row-label-stage'>
                            <label className='label-view'>Etapa:</label>
                        </div>
                        <p style={{marginLeft: '5px'}}>
                            {inputStage}
                        </p>
                    </div>
                    <div className='row-label-stage' style={{marginTop: '5px'}}>
                        <label className='label-view'>Requisito:</label>
                    </div>
                    <p style={{marginBottom: '5px'}}>
                        {inputRequirements}
                    </p>
                    <div className="row-label-p">
                        <div className='row-label-stage'>
                            <label className='label-view'>Cod:</label>
                        </div>
                        <p style={{marginLeft: '5px'}}>
                            {inputCod}
                        </p>
                    </div>
                    <div className='row-label-stage' style={{marginTop: '5px'}}>
                        <label className='label-view'>Indicador:</label>
                    </div>
                    <p style={{marginBottom: '5px'}}>
                        {inputIndicator}
                    </p>
                    <div className="row-label-p">
                        <div className='row-label-stage'>
                            <label className='label-view'>Ativo:</label>
                        </div>
                        <p style={{marginLeft: '5px'}}>
                            {indicatorIsActive ? 'Sim' : 'Não'}
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
}