import '../personalData.css'
import React, { useState, useEffect } from 'react';
import EditProfile from '../EditProfile/editProfile';
import axios from 'axios';
import MessageRequest from '../../../components/CardMessage/MessageRequest/messageRequest';
import { activeUser, editUser } from '../../../api/editUser';
import { registerUserLogs } from '../../../api/userLogs';

export default function AdminPersonalData({request}: {request: any}) {
    const [nameProfile, setNameProfile] = useState<string>('');
    const [emailProfile, setEmailProfile] = useState<string>('');
    const [phoneProfile, setPhoneProfile] = useState<string>('');
    const [profileData, setProfileData] = useState<string>('');
    const [cpfProfile, setCpfProfile] = useState<string>('');
    const [activeProfile, setActiveProfile] = useState<boolean>(false);
    const [companyProfile, setCompanyProfile] = useState<string>('');
    const [cnpjProfile, setCnpjProfile] = useState<string>('');
    const [officeProfile, setOfficeProfile] = useState<string>('');
    const [cepProfile, setCepProfile] = useState<string>('');
    const [idCepProfile, setIdCepProfile] = useState<number>(0);
    const [addressProfile, setAddressProfile] = useState<string>('');
    const [numProfile, setNumProfile] = useState<string>('');
    const [complementProfile, setComplementProfile] = useState<string>('');
    const [neighborhoodProfile, setNeighborhoodProfile] = useState<string>('');
    const [cityProfile, setCityProfile] = useState<string>('');
    const [ufProfile, setUfProfile] = useState<string>('');
    const [stateProfile, setStateProfile] = useState<string>('');
    const [countryProfile, setCountryProfile] = useState<string>('');
    const [activeEdit, setActiveEdit] = useState<boolean>(false);
    const list_page = window.location.href.split('/')
    const page = list_page[list_page.length - 1]
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            const profileRequest = request;
            setNameProfile(profileRequest['name']);
            setEmailProfile(profileRequest['email']);
            setPhoneProfile(profileRequest['phone']);
            setCpfProfile(profileRequest['cpf']);
            setProfileData(profileRequest['groups']);
            setActiveProfile(profileRequest['is_active']);
            setCompanyProfile(profileRequest['company']);
            setCnpjProfile(profileRequest['cnpj']);
            setOfficeProfile(profileRequest['office']);
            setCepProfile(profileRequest['cep_cep_address']);
            setIdCepProfile(profileRequest['cep_address']);
            setAddressProfile(profileRequest['address_cep_address']);
            setNumProfile(profileRequest['num_cep_address']);
            setComplementProfile(profileRequest['complement_cep_address']);
            setNeighborhoodProfile(profileRequest['neighborhood_cep_address']);
            setCityProfile(profileRequest['city_cep_address']);
            setUfProfile(profileRequest['uf_cep_address']);
            setStateProfile(profileRequest['state_cep_address']);
            setCountryProfile(profileRequest['country_cep_address']);
        };
        fetchData();
    }, []);

    const handleActiveEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveEdit(true);
    }

    const handleActiveUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const responseActive: any = await activeUser(emailProfile);       
       
        if(responseActive.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Usuário ativado com sucesso.');
            registerUserLogs("Ativou Usuário.");
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Não foi possível ativar usuário específico. Tente novamente mais tarde.')
        }
    }

    const handleDeactiveUser = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        const responseEditUser: any = await editUser(nameProfile, emailProfile, phoneProfile, cpfProfile, companyProfile, cnpjProfile, officeProfile, idCepProfile, false);

        if (responseEditUser.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Usuário Inativado.')
            registerUserLogs("Inativou usuário.");
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Requisição não foi bem sucedida. Por favor, tente novamente.')
        }
    }


    return (
        <section className='section-profile'>
            <EditProfile activeProps={activeEdit} request={request} url='/usuarios' profileUser={request['groups']} func='admin' />
            <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest}/>
            <h3>1. Dados Pessoais</h3>
            <ul>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Nome:</h4>
                        <p className='p-data-profile'>{nameProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>E-mail:</h4>
                        <p className='p-data-profile'>{emailProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Telefone:</h4>
                        <p className='p-data-profile'>{phoneProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>CPF:</h4>
                        <p className='p-data-profile'>{cpfProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Perfil:</h4>
                        <p className='p-data-profile'>{profileData}</p>
                    </div>
                </li>
            </ul>
            <hr className='divider-profile' />
            <h3>2. Empresa</h3>
            <ul>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Empresa:</h4>
                        <p className='p-data-profile'>{companyProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>CNPJ:</h4>
                        <p className='p-data-profile'>{cnpjProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Cargo:</h4>
                        <p className='p-data-profile'>{officeProfile}</p>
                    </div>
                </li>
            </ul>
            <hr className='divider-profile' />
            <h3>3. Endereço</h3>
            <ul>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>CEP:</h4>
                        <p className='p-data-profile'>{cepProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 style={{ display: 'flex!important', alignItems: 'flex-start!important', fontSize: '12px' }}>Endereço:</h4>
                        <p style={{ textAlign: 'justify' }}>{addressProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Número:</h4>
                        <p className='p-data-profile'>{numProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Complemento:</h4>
                        <p className='p-data-profile'>{complementProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Bairro:</h4>
                        <p className='p-data-profile'>{neighborhoodProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>Cidade:</h4>
                        <p className='p-data-profile'>{cityProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>UF:</h4>
                        <p className='p-data-profile'>{ufProfile}</p>
                    </div>
                </li>
                <li>
                    <div className='row-profile'>
                        <h4 className='title-data-profile'>País:</h4>
                        <p className='p-data-profile'>{countryProfile}</p>
                    </div>
                </li>
            </ul>
            <div className='row-button-admin-users'>
                <button className='button-admin-users' onClick={handleActiveEdit}>editar</button>
                {activeProfile === false ?
                    (<button className='button-admin-users' onClick={handleActiveUser}>ativar usuário</button>) :
                    (<button className='button-admin-users' onClick={handleDeactiveUser}>inativar usuário</button>)
                }
            </div>
        </section>
    );
}