import MessageRequest from "../CardMessage/MessageRequest/messageRequest";
import './modalImportFile.css'
import React, { useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import axios from 'axios';
import FileUploadButton from "../FileUploadButton/fileUploadButton";
import FileUploadArea from "../FileUploadArea/fileUploadArea";
import Loading from "../Loading/loading";
import { categories } from "../../api/categoriesStages";

type ModalImportFileProps = {
    activeModal: boolean, 
    titleModal: string, 
    idCategorie: number, 
    idStage: number,
    open: any
}

export default function ModalImportFile({activeModal, titleModal, idCategorie, idStage, open}: ModalImportFileProps) {
    const [active, setActive] = useState(false);
    const [activeModalUpload, setActiveModalUpload] = useState(false);
    const [dataCategories, setDataCategories] = useState('');
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [errorCategories, setErrorCategories] = useState(null);
    const [activeMessageRequest, setActiveMessageRequest] = useState(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState('');
    const [messageRequest, setMessageRequest] = useState('');
    const [nameFile, setNameFile] = useState('')
    const [fileUpload, setFileUpload] = useState(null)
    const [activeLoading, setActiveLoading] = useState(false)

    useEffect(() => {
        setActive(activeModal);
        setActiveModalUpload(activeModal);
    }, [activeModal]);

    const handleFileSelect = (file: any) => {
        setNameFile(file.name);
        setFileUpload(file);
    };

    useEffect(() => {

        const fetchData = async () => {
            const responseCategories = await categories();

            setDataCategories(responseCategories);
        };
    
        fetchData();
    }, [activeModal]);

    const handleCheckboxChange = (categoryId: number) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalUpload(false);
        setActive(false);
        open(false);
    };

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveModalUpload(false);
        setActiveLoading(true);

        if (titleModal === 'Nova Etapa') {
            try {
                const formData = new FormData();
                if (fileUpload !== null) {
                    formData.append('file', fileUpload);
                }
                formData.append('categories', JSON.stringify([idCategorie]));
        
                const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/', formData, {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("access_token")
                    }
                });
                
                if (response.status === 201) {
                    setActiveLoading(false);
                    setActiveMessageRequest(false);
                    setActiveMessageRequest(true);
                    setTitleMessageRequest('Sucesso:');
                    setMessageRequest('Cadatrado com sucesso.')
                }
            } catch(error) {
                setActiveLoading(false);    
                setActiveModalUpload(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            }
        } 

        if (titleModal === 'Novo Indicador') {
            try {
                const formData = new FormData();
                formData.append('stage_id', String(idStage));
                if (fileUpload !== null) {
                    formData.append('file', fileUpload);
                }
                formData.append('categories', JSON.stringify(selectedCategories));
        
                const response = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/indicators/', formData,
                {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("access_token")
                    }
                });
                
                if (response.status === 201) {
                    setActiveLoading(false);
                    setActiveMessageRequest(false);
                    setActiveMessageRequest(true);
                    setTitleMessageRequest('Sucesso:');
                    setMessageRequest('Cadatrado com sucesso.')
                }
            } catch(error) {
                setActiveLoading(false);
                setActiveModalUpload(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            }
        } 
    }

    const handleDownload = (event: any) => {
        let fileUrl = '/escala_indicadores_por_area.csv';

        if (titleModal === "Nova Etapa") {
            fileUrl = '/escalas_indicadores.csv';
        }
    
        const link = document.createElement('a');
        link.href = fileUrl;
        if (titleModal === "Novo Indicador") {
            link.download = 'escala_indicadoress_por_area.csv';
        }

        if (titleModal === "Nova Etapa") {
            link.download = 'escalas_indicadores.csv';
        }
    
        document.body.appendChild(link);
    
        link.click();
    
        document.body.removeChild(link);
    };
    
    return(
        <div className={active ? 'background-import-file' : 'modal-import-file-none'}>
            <Loading activeProps={activeLoading}/>
            <div className='col-message'>
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            </div>
            <div className={activeModalUpload ? 'modal-import-file' : 'modal-import-file-none'}>
                <form className='form-register'>
                    <div className='col-title-image-nav'>
                        <img
                            src="/amz.png"
                            alt="Imagem de cadastro"
                            className="register-image-nav"
                            style={{'marginLeft': '15px'}}
                        />
                        <h3> {titleModal} </h3>
                        <hr className='divider-modal' />
                    </div>
                    <FileUploadArea onFileSelect={handleFileSelect} nameFile={nameFile}/>
                    <p style={{'textAlign': 'center'}}>
                        Para a importação de arquivos é necessário que esteja dentro do modelo padrão, disponível para download:
                    </p>
                    <div className="row-buttons-import">
                        <button className="button-download-model" onClick={handleDownload}>
                            download modelo
                        </button>
                        <FileUploadButton onFileSelect={handleFileSelect}/>
                    </div>
                    {titleModal === 'Novo Indicador' && (
                        <div>
                            <div className='row-label'>
                                <label className='label-register'>Categorias</label>
                                <p className='required'>*</p>
                            </div>
                            <div className='col-checkbox'>
                                <ul  style={{'margin': '10px 0 0'}}>
                                    {Array.isArray(dataCategories) && dataCategories.map(category => (
                                        <li key={category.id} className='li-categories'>
                                            <label style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }} className='label-check'>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCategories.includes(category.id)}
                                                    onChange={() => handleCheckboxChange(category.id)}
                                                />
                                                {category.categorie}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <span>{errorCategories}</span>
                        </div>
                    )}
                    <div className='buttons-register-stage'>
                        <button className='button-new-stage' onClick={handleSubmit} type="button">
                            cadastrar
                        </button>
                        <button className='button-cancel-stage' onClick={handleCancel}>
                            <u>cancelar</u>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}