import './personalData.css'
import Navbar from '../../components/Navbar/navbar' 
import ColProfile from '../../components/ColProfile/colProfile';
import React, { useState, useEffect } from 'react';
import EditProfile from './EditProfile/editProfile';
import Loading from '../../components/Loading/loading';
import Drawer from '@mui/material/Drawer';

export default function PersonalData({user, profileProps}: any) {
    const [nameProfile, setNameProfile] = useState('');
    const [emailProfile, setEmailProfile] = useState('');
    const [phoneProfile, setPhoneProfile] = useState('');
    const [profileData, setProfileData] = useState('');
    const [cpfProfile, setCpfProfile] = useState('');
    const [activeProfile, setActiveProfile] = useState('');
    const [companyProfile, setCompanyProfile] = useState('');
    const [cnpjProfile, setCnpjProfile] = useState('');
    const [officeProfile, setOfficeProfile] = useState('');
    const [cepProfile, setCepProfile] = useState('');
    const [addressProfile, setAddressProfile] = useState('');
    const [numProfile, setNumProfile] = useState('');
    const [complementProfile, setComplementProfile] = useState('');
    const [neighborhoodProfile, setNeighborhoodProfile] = useState('');
    const [cityProfile, setCityProfile] = useState('');
    const [ufProfile, setUfProfile] = useState('');
    const [stateProfile, setStateProfile] = useState('');
    const [countryProfile, setCountryProfile] = useState('');
    const [activeEdit, setActiveEdit] = useState(false);
    const [requestProfile, setRequestProfile] = useState([]);
    const [profileUser, setProfileUser] = useState('');
    const list_page = window.location.href.split('/')
    const page = list_page[list_page.length - 1]
    const [activeLoading, setActiveLoading] = useState(true)
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRequest = user;
                const profileRequest = profileProps;
                setRequestProfile(userRequest);
                setNameProfile(userRequest.name);
                setEmailProfile(userRequest.email);
                setPhoneProfile(userRequest['phone']);
                setCpfProfile(userRequest['cpf']);
                setProfileData(profileRequest);
                setActiveProfile(userRequest['is_active']);
                setCompanyProfile(userRequest['company']);
                setCnpjProfile(userRequest['cnpj']);
                setOfficeProfile(userRequest['office']);
                setCepProfile(userRequest['cep_cep_address']);
                setAddressProfile(userRequest['address_cep_address']);
                setNumProfile(userRequest['num_cep_address']);
                setComplementProfile(userRequest['complement_cep_address']);
                setNeighborhoodProfile(userRequest['neighborhood_cep_address']);
                setCityProfile(userRequest['city_cep_address']);
                setUfProfile(userRequest['uf_cep_address']);
                setCountryProfile(userRequest['country_cep_address']);
                setTimeout(() => {
                    setActiveLoading(false);
                }, 3000);
            } catch (error) {
                console.error('Erro ao buscar perfil:', error);
            }
        };

        fetchData();
    }, [user, profileProps]);

    const handleActiveEdit = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setActiveEdit(true);
    }

    return (
        <div className='personal-data'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Configurações" menu={toggleDrawer(true)}/>
            <section>
                <div className='container-sidebar'>
                    <div className="sidebar-personal-data">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'dados-pessoais' && !activeEdit ? 'selected' : ''} href="dados-pessoais">Dados Pessoais</a>
                            <a className={activeEdit ? 'selected' : ''} onClick={handleActiveEdit}>Editar Dados</a>
                            <a className={page === 'alterar-senha' ? 'selected' : ''} href='alterar-senha'>Alterar Senha</a>
                            <a className={page === 'relatar-problema' && !activeEdit ? 'selected' : ''} href='/relatar-problema'>Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </div>
                <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%", width: "250px", overflowY: "hidden!important"}}>
                    <div className="sidebar-home">
                        <div className="menu_stages">
                            <a href="pagina-inicial">Voltar</a>
                            <a className={page === 'dados-pessoais' && !activeEdit ? 'selected' : ''} href="dados-pessoais">Dados Pessoais</a>
                            <a className={activeEdit ? 'selected' : ''} onClick={handleActiveEdit}>Editar Dados</a>
                            <a className={page === 'alterar-senha' ? 'selected' : ''} href='alterar-senha'>Alterar Senha</a>
                            <a className={page === 'relatar-problema' && !activeEdit ? 'selected' : ''} href='/relatar-problema'>Relatar Problema</a>
                        </div>
                        <ColProfile />
                    </div>
                </Drawer>
                <section className='section-profile'>
                    <EditProfile activeProps={activeEdit} request={user} url='/dados-pessoais' profileUser={profileProps} func='client' />
                    <h3>1. Dados Pessoais</h3>
                    <ul>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Nome:</h4>
                                <p className='p-data-profile'>{nameProfile}</p>
                            </div>
                        </li>
                        <li>
                        <div className='row-profile'>
                            <h4 className='title-data-profile'>E-mail:</h4>
                            <p className='p-data-profile'>{emailProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Telefone:</h4>
                                <p className='p-data-profile'>{phoneProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>CPF:</h4>
                                <p className='p-data-profile'>{cpfProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Perfil:</h4>
                                <p className='p-data-profile'>{localStorage.getItem('profile')}</p>
                            </div>
                        </li>
                    </ul>
                    <h3>2. Empresa</h3>
                    <ul>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Empresa:</h4>
                                <p className='p-data-profile'>{companyProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>CNPJ:</h4>
                                <p className='p-data-profile'>{cnpjProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Cargo:</h4>
                                <p className='p-data-profile'>{officeProfile}</p>
                            </div>
                        </li>
                    </ul>
                    <h3>3. Endereço</h3>
                    <ul>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>CEP:</h4>
                                <p className='p-data-profile'>{cepProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 style={{ display: 'flex!important', alignItems: 'flex-start!important', fontSize: '12px' }}>Endereço:</h4>
                                <p className='p-data-profile' style={{ textAlign: 'justify' }}>{addressProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Número:</h4>
                                <p className='p-data-profile'>{numProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Complemento:</h4>
                                <p className='p-data-profile'>{complementProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Bairro:</h4>
                                <p className='p-data-profile'>{neighborhoodProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>Cidade:</h4>
                                <p className='p-data-profile'>{cityProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>UF:</h4>
                                <p className='p-data-profile'>{ufProfile}</p>
                            </div>
                        </li>
                        <li>
                            <div className='row-profile'>
                                <h4 className='title-data-profile'>País:</h4>
                                <p className='p-data-profile'>{countryProfile}</p>
                            </div>
                        </li>
                    </ul>
                </section>
            </section>
        </div>
    );
}