import './editProfile.css';
import React, { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import MessageRequest from '../../../components/CardMessage/MessageRequest/messageRequest';
import MessageCancel from '../../../components/CardMessage/messageCancel';
import { country } from '../../../components/ApiIbge/country';
import { uf } from '../../../components/ApiIbge/uf';
import { city } from '../../../components/ApiIbge/city';
import { editProfile } from '../../../api/editUser';
import { editAddress } from '../../../api/address';
import { registerUserLogs } from '../../../api/userLogs';

type EditProfileProps = {
    activeProps: boolean, 
    request?: any, 
    profileUser?: any, 
    url: string, 
    func?: any
}

export default function EditProfile({activeProps, request, profileUser, url, func}: EditProfileProps) {
    const [active, setActive] = useState(activeProps);

    useEffect(() => {
        setActive(activeProps);
    }, [activeProps]);

    const userOnline = localStorage.getItem('profile');

    const [stage, setStage] = useState<number>(1);
    const [inputName, setinputName] = useState<string>('');
    const [inputEmail, setinputEmail] = useState<string>('');
    const [inputPhone, setinputPhone] = useState<string>('');
    const [inputCpf, setinputCpf] = useState<string>('');
    const [inputCompany, setinputCompany] = useState<string>('');
    const [inputCnpj, setinputCnpj] = useState<string>('');
    const [inputOffice, setinputOffice] = useState<string>('');
    const [inputProfileUser, setinputProfileUser] = useState<string>('');
    const [inputCep, setinputCep] = useState<string>('');
    const [inputAddress, setinputAddress] = useState<string>('');
    const [inputNum, setinputNum] = useState<number>(0);
    const [inputComplement, setinputComplement] = useState<string>('');
    const [inputNeighborhood, setinputNeighborhood] = useState<string>('');
    const [inputCity, setinputCity] = useState<string>('');
    const [inputUf, setinputUf] = useState<string>('');
    const [inputCountry, setinputCountry] = useState<string>('');
    const [inputIdCep, setinputIdCep] = useState<number>(0);
    const [errorName, setErrorName] = useState<string | null>(null);
    const [errorEmail, setErrorEmail] = useState<string | null>(null);
    const [errorPhone, setErrorPhone] = useState<string | null>(null);
    const [errorCpf, setErrorCpf] = useState<string | null>(null);
    const [errorCompany, setErrorCompany] = useState<string | null>(null);
    const [errorCnpj, setErrorCnpj] = useState<string | null>(null);
    const [errorOffice, setErrorOffice] = useState<string | null>(null);
    const [errorCep, setErrorCep] = useState<string | null>(null);
    const [errorAddress, setErrorAddress] = useState<string | null>(null);
    const [errorNum, setErrorNum] = useState<string | null>(null);
    const [errorComplement, setErrorComplement] = useState<string | null>(null);
    const [errorNeighborhood, setErrorNeighborhood] = useState<string | null>(null);
    const [errorCity, setErrorCity] = useState<string | null>(null);
    const [errorUf, setErrorUf] = useState<string | null>(null);
    const [errorCountry, setErrorCountry] = useState<string | null>(null);
    const [activeMessage, setActiveMessage] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<boolean>(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRequest = request;
                const profileRequest = profileUser;
                setinputName(userRequest['name']);
                setinputEmail(userRequest['email']);
                setinputPhone(userRequest['phone']);
                setinputCpf(userRequest['cpf']);
                setinputOffice(userRequest['office']);
                setinputCompany(userRequest['company']);
                setinputCnpj(userRequest['cnpj']);
                setinputIdCep(userRequest['cep_address']);
                setinputCep(userRequest['cep_cep_address']);
                setinputAddress(userRequest['address_cep_address']);
                setinputNum(userRequest['num_cep_address']);
                setinputComplement(userRequest['complement_cep_address']);
                setinputNeighborhood(userRequest['neighborhood_cep_address']);
                setinputCity(userRequest['city_cep_address']);
                setinputUf(userRequest['uf_cep_address']);
                setinputCountry(userRequest['country_cep_address']);
                setinputProfileUser(profileRequest);
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        }

        fetchData();
    }, [request, profileUser]);

    const handleInputName = (event: ChangeEvent<HTMLInputElement>) => {
        setinputName(event.target.value);
        setErrorName(null);
    }

    const handleInputEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setinputEmail(event.target.value);
        setErrorEmail(null);
    }

    const handleInputPhone = (event: ChangeEvent<HTMLInputElement>) => {
        setinputPhone(event.target.value);
        setErrorPhone(null);
    }

    const handleInputCpf = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCpf(event.target.value);
        setErrorCpf(null);
    }

    const handleInputCompany = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCompany(event.target.value);
        setErrorCompany(null);
    }

    const handleInputCnpj = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCnpj(event.target.value);
        setErrorCnpj(null);
    }

    const handleInputOffice = (event: ChangeEvent<HTMLInputElement>) => {
        setinputOffice(event.target.value);
        setErrorOffice(null);
    }

    const handleInputCep = (event: ChangeEvent<HTMLInputElement>) => {
        setinputCep(event.target.value);
        setErrorCep(null);
    }

    const handleInputAddress = (event: ChangeEvent<HTMLInputElement>) => {
        setinputAddress(event.target.value);
        setErrorAddress(null);
    }

    const handleInputNum = (event: any) => {
        setinputNum(event.target.value);
        setErrorNum(null);
    }

    const handleInputComplement = (event: ChangeEvent<HTMLInputElement>) => {
        setinputComplement(event.target.value);
        setErrorComplement(null);
    }

    const handleInputNeighborhood = (event: ChangeEvent<HTMLInputElement>) => {
        setinputNeighborhood(event.target.value);
        setErrorNeighborhood(null);
    }

    const handleInputCity = (event: any) => {
        event.preventDefault();
        setinputCity(event.target.value);
        setErrorCity(null);
    }

    const handleInputUf = (event: any) => {
        setinputUf(event.target.value);
        setErrorUf(null);
    }

    const handleInputCountry = (event: any) => {
        setinputCountry(event.target.value);
        setErrorCountry(null);
    }

    const handleProfileUser = (event: any) => {
        setinputProfileUser(event.target.value);
    }

    const validateEmail = (email: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const prevStage = () => {
        setStage(stage - 1);
    }

    const advanceStage = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (stage === 1) {
            if (inputName.trim() === '') {
                setErrorName('Campo obrigatório.')
                return;
            } else {
                setErrorName('')
            }
    
            if (inputEmail.trim() === '') {
                setErrorEmail('Campo obrigatório.')
                return;
            } else if(!validateEmail(inputEmail)) {
                setErrorEmail('E-mail inválido.')
            } else {
                setErrorEmail('')
            }
    
            if (inputPhone.trim() === '') {
                setErrorPhone('Campo obrigatório.')
                return;
            } else {
                setErrorPhone('')
            }
    
            if (inputCpf.trim() === '') {
                setErrorCpf('Campo obrigatório.')
                return;
            } else {
                setErrorCpf('')
            }
        }

        if (stage === 2) {
            if (inputCompany.trim() === '') {
                setErrorCompany('Campo obrigatório.')
                return;
            } else {
                setErrorCompany('')
            }

            if (inputCnpj.trim() === '') {
                setErrorCnpj('Campo obrigatório.')
                return;
            } else {
                setErrorCnpj('')
            }
    
            if (inputOffice.trim() === '') {
                setErrorOffice('Campo obrigatório.')
                return;
            } else {
                setErrorOffice('')
            }
        }

        if (stage === 3) {

            if (inputNeighborhood.trim() === '') {
                setErrorNeighborhood('Campo obrigatório.')
                return;
            } else {
                setErrorNeighborhood('')
            }
    
            if (inputCity.trim() === '') {
                setErrorCity('Campo obrigatório.')
                return;
            } else {
                setErrorCity('')
            }
    
            if (inputUf.trim() === '') {
                setErrorUf('Campo obrigatório.')
                return;
            } else {
                setErrorUf('')
            }
    
            if (inputCountry.trim() === '') {
                setErrorCountry('Campo obrigatório.')
                return;
            } else {
                setErrorCountry('')
            }
        }

        setStage(stage + 1);
    }

    const clearInputsStage = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (stage === 1) {
            setinputName('');
            setinputEmail('');
            setinputPhone('');
            setinputCpf('');
        } else if (stage === 2) {
            setinputCompany('');
            setinputCnpj('');
            setinputOffice('');
        } else if (stage === 3) {
            setinputCep('');
            setinputAddress('');
            setinputNum(0);
            setinputComplement('');
        } else if (stage === 4) {
            setinputNeighborhood('');
            setinputCity('');
            setinputUf('');
            setinputCountry('');
        }
    }

    const handleSubmitClient = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputCep.trim() === '') {
            setErrorCep('Campo obrigatório.')
            return;
        } else {
            setErrorCep('')
        }

        if (inputAddress.trim() === '') {
            setErrorAddress('Campo obrigatório.')
            return;
        } else {
            setErrorAddress('')
        }

        axios.put(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/address/', {
            id: inputIdCep,
            cep: inputCep,
            address: inputAddress,
            num: inputNum,
            complement: inputComplement ? inputComplement : "null",
            neighborhood: inputNeighborhood,
            city: inputCity,
            uf: inputUf,
            country: inputCountry,
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        .then((response) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Edição realizada com sucesso.')
        })
        .catch((error) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique seus dados e tente novamente.')
        });

        axios.put(process.env.REACT_APP_URL_API_AMZ+'api/auth-amz/user/', {
            name: inputName,
            email: inputEmail,
            phone: inputPhone,
            cpf: inputCpf,
            company: inputCompany,
            cnpj: inputCnpj,
            office: inputOffice,
            is_active: true
        },
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        .then((response) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Edição realizada com sucesso.')
            registerUserLogs("Dados pessoais de usuário foram editados.");
        })
        .catch((error) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique seus dados e tente novamente.')
        });
    }

    const handleSubmitAdmin = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputCep.trim() === '') {
            setErrorCep('Campo obrigatório.')
            return;
        } else {
            setErrorCep('')
        }

        if (inputAddress.trim() === '') {
            setErrorAddress('Campo obrigatório.')
            return;
        } else {
            setErrorAddress('')
        }

        const responseEditAddress: any = await editAddress(inputIdCep, inputCep, inputAddress, inputNum, inputComplement, inputNeighborhood, inputCity, inputUf, inputCountry);
        
        if (responseEditAddress.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Edição realizada com sucesso.')
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique seus dados e tente novamente.')
        }

        const resonseEditProfile:any = await editProfile(inputName, inputEmail, inputPhone, inputCpf, inputCompany, inputCnpj, inputProfileUser, inputOffice, inputIdCep, true);
        
        if (resonseEditProfile.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Edição realizada com sucesso.')
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Requisição não foi bem sucedida. Por favor, verifique seus dados e tente novamente.')
        }
    }

    const classNameName = errorName ? 'input-register-error' : 'input-register';
    const classNameEmail = errorEmail ? 'input-register-error' : 'input-register';
    const classNamePhone = errorPhone ? 'input-register-error' : 'input-register';
    const classNameCpf = errorCpf ? 'input-register-error' : 'input-register';
    const classNameCompany = errorCompany ? 'input-register-error' : 'input-register';
    const classNameOffice = errorOffice ? 'input-register-error' : 'input-register';
    const classNameCep = errorCep ? 'input-register-error' : 'input-register';
    const classNameAddress = errorAddress ? 'input-register-error' : 'input-register';
    const classNameNum = errorNum ? 'input-register-error' : 'input-register';
    const classNameComplement = errorComplement ? 'input-register-error' : 'input-register';
    const classNameNeighborhood = errorNeighborhood ? 'input-register-error' : 'input-register';
    const classNameCity = errorCity ? 'input-register-error' : 'input-register';
    const classNameUf = errorUf ? 'input-register-error' : 'input-register';
    const classNameCountry = errorCountry ? 'input-register-error' : 'input-register';

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActiveMessage(!activeMessage);
        setReloadMessage(true)
    }

    const [dataCountry, setDataCountry] = useState([]);
    const [dataUf, setDataUf] = useState([]);
    const [dataCity, setDataCity] = useState([]);

    useEffect(() => {
        const countryData = async () => {
            try {
                const countryRequest = await country();
                setDataCountry(countryRequest);
            } catch(e) {
                return [];
            }
        }
        countryData();
    }, []);

    useEffect(() => {
        const ufData = async () => {
            try {
                const ufRequest = await uf();
                setDataUf(ufRequest);
            } catch(e) {
                return [];
            }
        }
        ufData();
    }, []);

    useEffect(() => {
        const cityData = async () => {
            try {
                const cityRequest = await city(inputUf);
                setDataCity(cityRequest);
            } catch(e) {
                return [];
            }
        }
        
        if (inputUf != '') {
            cityData();
        }
    }, [inputUf]);

    return (
        <div className={active === true ? 'edit-profile' : 'edit-profile-none'}>
            <div className='card-message'>
                <MessageCancel activeProps={activeMessage} reloadProps={reloadMessage} />
            </div>
            <div className='card-message'>
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            </div>
            <div className='row-1'>
                    <div className='col-title-sub-title'>
                        <div className='col-title-image'>
                            <img
                                src="/amz.png"
                                alt="Imagem de cadastro"
                                className="register-image"
                            />
                            <h2 style={{'margin': '0'}}>EDITAR DADOS</h2>
                        </div>
                        <p className='p-message'>
                            *Os campos sinalizados em <p className='p-message-red'>VERMELHO</p> são obrigatórios.
                        </p>
                    </div>
                    <button className='button-register-clear' onClick={clearInputsStage}>limpar tudo</button>
                </div>
                {stage === 1 && (
                    <form className='form-register'>
                        <div className='row-childs'>
                            <div className='col-childs1'>
                                <h3 className='h3-register'>1. DADOS PESSOAIS</h3>
                                <div className='row-label'>
                                    <label className='label-register'>nome</label>
                                    <p className='required'>*</p>
                                    <input type="text" onChange={handleInputName} className={classNameName} value={inputName} style={{ 'marginLeft': '33px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '86px' }}>{errorName}</span>
                                <div className='row-label'>
                                    <label className='label-register'>email</label>
                                    <p className='required'>*</p>
                                    <input type="email" onChange={handleInputEmail} className={classNameEmail} value={inputEmail} style={{ 'marginLeft': '35px' }} disabled/>
                                </div>
                                <span style={{ 'marginLeft': '86px' }}>{errorEmail}</span>
                                <div className='row-label'>
                                    <label className='label-register'>telefone</label>
                                    <p className='required'>*</p>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        onChange={handleInputPhone}
                                        value={inputPhone}
                                        className={classNamePhone}
                                        style={{ marginLeft: '14px' }}
                                    />
                                </div>
                                <span style={{ 'marginLeft': '86px' }}>{errorPhone}</span>
                                <div className='row-label'>
                                    <label className='label-register'>cpf</label>
                                    <p className='required'>*</p>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar="_"
                                        onChange={handleInputCpf}
                                        value={inputCpf}
                                        className={classNameCpf}
                                        style={{ marginLeft: '52px' }}
                                    />
                                </div>
                                <span style={{ 'marginLeft': '86px' }}>{errorCpf}</span>
                                {func === 'admin' ?
                                    (<div className='row-label'>
                                        <label className='label-register'>perfil</label>
                                        <p className='required'>*</p>
                                        <select className="select-edit-users" value={inputProfileUser} onChange={handleProfileUser} style={{ 'marginLeft': '36px' }}>
                                            <option value="">Selecione...</option>
                                            <option value="administrador">Administrador</option>
                                            <option value="auditor">Auditor</option>
                                            <option value="camara">Camara</option>
                                            <option value="cliente">Cliente</option>
                                        </select>
                                    </div>) :
                                    <div>{}</div>
                                }
                            </div>
                            <div className='vertical-line-profile'></div>
                        </div>
                        <div className='div-button-register'>
                            <button onClick={handleCancel}>cancelar</button>
                            <button onClick={advanceStage}>próximo</button>
                        </div>
                    </form>
                )}
                {stage === 2 && (
                    <form className='form-register'>
                        <div className='row-childs'>
                            <div className='col-childs1'>
                                <h3 className='h3-register'>2. EMPRESA</h3>
                                <div className='row-label'>
                                    <label className='label-register'>empresa</label>
                                    <p className='required'>*</p>
                                    <input type="text" onChange={handleInputCompany} className={classNameCompany} value={inputCompany} style={{ 'marginLeft': '15px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '88px' }}>{errorCompany}</span>
                                <div className='row-label'>
                                    <label className='label-register'>cnpj</label>
                                    <p className='required'>*</p>
                                    <InputMask
                                        mask="99.999.999/9999-99"
                                        maskChar="_"
                                        onChange={handleInputCnpj}
                                        value={inputCnpj}
                                        style={{ marginLeft: '47px' }}
                                    />
                                </div>
                                <span style={{ 'marginLeft': '86px' }}>{errorCnpj}</span>
                                <div className='row-label'>
                                    <label className='label-register'>cargo</label>
                                    <p className='required'>*</p>
                                    <input type="text" onChange={handleInputOffice} className={classNameOffice} value={inputOffice} style={{ 'marginLeft': '36px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '88px' }}>{errorOffice}</span>
                                </div>
                                <div className='vertical-line-profile'></div>
                            </div>
                            <div className='div-button-register'>
                            <button onClick={handleCancel}>cancelar</button>
                            <div className='div-two-button'>
                                <button onClick={prevStage}>anterior</button>
                                <button onClick={advanceStage}>próximo</button>
                            </div>
                        </div>
                    </form>
                )}
                {stage === 3 && (
                    <form className='form-register'>
                        <div className='row-childs'>
                            <div className='col-childs1'>
                                <h3 className='h3-register'>3. ENDEREÇO</h3>
                                <div className='row-label'>
                                    <label className='label-register'>país</label>
                                    <p className='required'>*</p>
                                    <select className="select-register" value={inputCountry} onChange={handleInputCountry} style={{ 'marginLeft': '34px' }}>
                                        <option value="">Selecione...</option>
                                        {dataCountry.map((country: any) => (
                                            <option key={country.id.M49} value={country.nome}>
                                                {country.nome}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <span style={{ 'marginLeft': '73px' }}>{errorCountry}</span>
                                <div className='row-label'>
                                    <label className='label-register'>uf</label>
                                    <p className='required'>*</p>
                                    {inputCountry !== "Brasil" ?
                                        (<input type="text" onChange={handleInputUf} className={classNameUf} value={inputUf} style={{ 'marginLeft': '50px' }}/>) :
                                        (<select className="select-register" value={inputUf} onChange={handleInputUf} style={{ 'marginLeft': '50px' }}>
                                            <option value="">Selecione...</option>
                                            {dataUf.map((uf: any) => (
                                                <option key={uf.id} value={uf.sigla}>
                                                    {uf.sigla}
                                                </option>
                                            ))}
                                        </select>)
                                    }
                                </div>
                                <span style={{ 'marginLeft': '73px' }}>{errorUf}</span>
                                <div className='row-label'>
                                    <label className='label-register'>cidade</label>
                                    <p className='required'>*</p>
                                    {inputCountry !== "Brasil" ?
                                        (<input type="text" onChange={handleInputCity} className={classNameCity} value={inputCity} style={{ 'marginLeft': '15px' }}/>) :
                                        (<select className="select-register" value={inputCity} onChange={handleInputCity} style={{ 'marginLeft': '15px' }}>
                                            <option value="">Selecione...</option>
                                            {dataCity.map((city: any) => (
                                                <option key={city.id} value={city.nome}>
                                                    {city.nome}
                                                </option>
                                            ))}
                                        </select>)
                                    }
                                </div>
                                <span style={{ 'marginLeft': '121px' }}>{errorCity}</span>
                                <div className='row-label'>
                                    <label className='label-register'>bairro</label>
                                    <p className='required'>*</p>
                                    <input type="text" onChange={handleInputNeighborhood} value={inputNeighborhood} className={classNameNeighborhood}  style={{ 'marginLeft': '20px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '121px' }}>{errorNeighborhood}</span>
                            </div>
                            <div className='vertical-line-profile'></div>
                        </div>
                        <div className='div-button-register'>
                            <button onClick={handleCancel}>cancelar</button>
                            <div className='div-two-button'>
                                <button onClick={prevStage}>anterior</button>
                                <button onClick={advanceStage}>próximo</button>
                            </div>
                        </div>
                    </form>
                )}
                {stage === 4 && (
                    <form className='form-register'>
                        <div className='row-childs'>
                            <div className='col-childs1'>
                                <div className='row-label'>
                                    <label className='label-register'>cep</label>
                                    <p className='required'>*</p>
                                    <input type="text" onChange={handleInputCep} className={classNameCep} value={inputCep} style={{ 'marginLeft': '85px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '121px' }}>{errorCep}</span>
                                <div className='row-label'>
                                    <label className='label-register'>endereço</label>
                                    <p className='required'>*</p>
                                    <input type="text" onChange={handleInputAddress} value={inputAddress} className={classNameAddress} style={{ 'marginLeft': '41px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '121px' }}>{errorAddress}</span>
                                <div className='row-label'>
                                    <label className='label-register'>número</label>
                                    <p className='required'>*</p>
                                    <input type="number" onChange={handleInputNum} className={classNameNum} value={inputNum}  style={{ 'marginLeft': '54px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '120px' }}>{errorNum}</span>
                                <div className='row-label'>
                                    <label className='label-register'>complemento</label>
                                    <input type="text" onChange={handleInputComplement} className={classNameComplement} value={inputComplement} style={{ 'marginLeft': '17px' }}/>
                                </div>
                                <span style={{ 'marginLeft': '121px' }}>{errorComplement}</span>
                            </div>
                            <div className='vertical-line-profile'></div>
                        </div>
                        <div className='div-button-register'>
                            <button onClick={handleCancel}>cancelar</button>
                            <div className='div-two-button'>
                                <button onClick={prevStage}>anterior</button>
                                {func === 'admin' ?
                                    (<button onClick={handleSubmitAdmin}>salvar</button>) :
                                    <button onClick={handleSubmitClient}>salvar</button>
                                }
                            </div>
                        </div>
                    </form>
                )} 
        </div>
    );
}