import './spreadsheet.css'
import Home from '../Home/home' 
import Bar from '../../components/Bar/bar'
import React from 'react'; 

export default function NewSpreadsheet() {
    return (
        <div>
            <Home />
            <Bar link='sivicultura' title="SIVICULTURA"></Bar>
        </div>
    );
}