import Loading from "../../components/Loading/loading";
import Navbar from "../../components/Navbar/navbar";
import React, { useState, useEffect } from "react";
import { viewUserLogs } from "../../api/userLogs";

export default function Monitoring() {
    const [results, setResults] = useState<any>([]);
    const [day, setDay] = useState<any>([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]);
    const [daySelected, setDaySelected] = useState();
    const [month, setMonth] = useState<any>([
        {
            label: 'janeiro',
            value: 1
        },
        {
            label: 'fevereiro',
            value: 2
        },
        {
            label: 'março',
            value: 3
        },
        {
            label: 'abril',
            value: 4
        },
        {
            label: 'maio',
            value: 5
        },
        {
            label: 'junho',
            value: 6
        },
        {
            label: 'julho',
            value: 7
        },
        {
            label: 'agosto',
            value: 8
        },
        {
            label: 'setembro',
            value: 9
        },
        {
            label: 'outubro',
            value: 10
        },
        {
            label: 'novembro',
            value: 11
        },
        {
            label: 'dezembro',
            value: 12
        },
    ]);
    const [monthSelected, setMonthSelected] = useState();
    const [year, setYear] = useState<any>([2024,2025,2026,2027,2028,2029,2030]);
    const [yearSelected, setYearSelected] = useState();
    const [activeLoading, setActiveLoading] = useState<boolean>(true)

    useEffect(() => {
        const fetchData = async () => {
            const logsRequest = await viewUserLogs(
                daySelected,
                monthSelected,
                yearSelected
            );
                
            setResults(logsRequest);
            if (results !== null || results !== '') {
                setActiveLoading(false)
            } 
        };

        fetchData();
    }, [daySelected, monthSelected, yearSelected]);

    const handleDay = (event: any) => {
        setDaySelected(event.target.value);
    }

    const handleMonth = (event: any) => {
        setMonthSelected(event.target.value);
    }

    const handleYear = (event: any) => {
        setYearSelected(event.target.value);
    }

    return (
        <div className='view-stages'>
            <Loading activeProps={activeLoading}/>
            <Navbar title="Sistema AMZ" user={true} search={true} />
            <section className='section-view-stages'>
                <div className='col-title-image-view-stages'>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image"
                    />
                    <h2>Monitoramento</h2>
                    <hr className='divider-title' style={{ 'width': '90%' }} />
                </div>
                <div className='row-view-stages' style={{'paddingBottom': 0}}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className='row-label'>
                            <label className='label-register'>Dia</label>
                        </div>
                        <select className="select-register" style={{ width: "260px", marginBottom: "20px" }} value={daySelected} onChange={handleDay}>
                            <option value="">Selecione...</option>
                            {day.map((d: any, index: number) => (
                                <option value={d}>{d}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className='row-label'>
                            <label className='label-register'>Mês</label>
                        </div>
                        <select className="select-register" style={{ width: "260px", marginBottom: "20px" }} value={monthSelected} onChange={handleMonth}>
                            <option value="">Selecione...</option>
                            {month.map((d: any, index: number) => (
                                <option value={d.value}>{d.label}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className='row-label'>
                            <label className='label-register'>Ano</label>
                        </div>
                        <select className="select-register" style={{ width: "260px", marginBottom: "20px" }} value={yearSelected} onChange={handleYear}>
                            <option value="">Selecione...</option>
                            {year.map((d: any, index: number) => (
                                <option value={d}>{d}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='row-view-stages' style={{'paddingBottom': 0}}>
                    <h3 style={{'width': '300px', marginRight: '180px'}} className="title-user-monitoring">
                        usuário
                    </h3>
                    <h3 style={{'width': '180px', marginRight: '300px'}}>
                        ação
                    </h3>
                    <h3 style={{'width': 'auto'}}>
                        data
                    </h3>
                    <h3 style={{width: '75px'}}>
                        hora
                    </h3>
                </div>
                <div className='col-users'>
                    {results ? results.map((result: any, index: number) => (
                        <div className='col-users'>
                            <div className='row-view-stages' style={{'height': '40px'}}>
                                <p key={index} style={{'width': '600px'}}>
                                    {result.user_id_name}
                                </p>
                                <p key={index} style={{'width': '600px'}}>
                                    {result.action}
                                </p>
                                <p key={index} style={{width: '120px'}}>
                                    {`${result.day}/${result.month}/${result.year}`}
                                </p>
                                <p key={index} style={{width: '80px'}}>
                                    {result.hour}
                                </p>
                            </div>
                            <hr className='divider-stage' style={{'marginBottom': 0}} />
                        </div>
                    )) : 
                        []
                    }
                    {results.length === 0 && (<div className="div-data-null">
                        <p>Não há dados cadastrados</p>
                    </div>)}
                </div>
            </section>
        </div>
    );
}