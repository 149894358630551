// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-cards-stages {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 200px;
  }
  
  .card-stage {
    display: flex;
    justify-content: center;
    text-decoration: none;
    background-color: #E6E6E6;
    color: #000;
    margin-right: 30px;
    margin-bottom: 15px;
    padding: 7px;
    width: 250px;
    cursor: pointer;
  }

.row-button-stage {
    display: flex;
    width: 80%;
    justify-content: end;
    margin-bottom: 10px;
}

.button-stage {
    background-color: #006633;
    color: #fff;
    width: 168px;
    font-weight: 400;
    margin: 0;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CardStages/cardStages.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;AAEF;IACI,aAAa;IACb,UAAU;IACV,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,SAAS;IACT,gBAAgB;AACpB","sourcesContent":[".col-cards-stages {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    padding-right: 200px;\n  }\n  \n  .card-stage {\n    display: flex;\n    justify-content: center;\n    text-decoration: none;\n    background-color: #E6E6E6;\n    color: #000;\n    margin-right: 30px;\n    margin-bottom: 15px;\n    padding: 7px;\n    width: 250px;\n    cursor: pointer;\n  }\n\n.row-button-stage {\n    display: flex;\n    width: 80%;\n    justify-content: end;\n    margin-bottom: 10px;\n}\n\n.button-stage {\n    background-color: #006633;\n    color: #fff;\n    width: 168px;\n    font-weight: 400;\n    margin: 0;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
