// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.header {
  background-color: #006633;
  color: #fff;
  display: flex;
  font-weight: bold;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  font-size: 20px;
}

.sidebar {
  display: flex;
  height: 10%;
  width: 250px;
  position: fixed;
  top: 60px;
  left: 0;
  background-color: #ffffff;
  padding-top: 0px;
  flex-direction: column;
}

.vertical-line {
  width: 5; 
  background-color: #006633; 
  height: 85%; 
  margin-right: 10px;
}

.menu_stages {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar a {
  padding: 15px;
  text-decoration: none;
  font-size: 17px;
  color: #006633;
  font-weight: bold;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
}

.sidebar a:hover {
  background-color: #e6e6e6;
}

.content {
  margin-left: 250px;
  flex-grow: 1;
  padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,eAAe;EACf,SAAS;EACT,OAAO;EACP,yBAAyB;EACzB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,QAAQ;EACR,yBAAyB;EACzB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf","sourcesContent":["body {\n  padding: 0;\n  margin: 0;\n  font-family: Arial, sans-serif;\n  overflow-x: hidden;\n}\n\n.header {\n  background-color: #006633;\n  color: #fff;\n  display: flex;\n  font-weight: bold;\n  align-items: center;\n  height: 60px;\n  padding: 0 20px;\n  font-size: 20px;\n}\n\n.sidebar {\n  display: flex;\n  height: 10%;\n  width: 250px;\n  position: fixed;\n  top: 60px;\n  left: 0;\n  background-color: #ffffff;\n  padding-top: 0px;\n  flex-direction: column;\n}\n\n.vertical-line {\n  width: 5; \n  background-color: #006633; \n  height: 85%; \n  margin-right: 10px;\n}\n\n.menu_stages {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.sidebar a {\n  padding: 15px;\n  text-decoration: none;\n  font-size: 17px;\n  color: #006633;\n  font-weight: bold;\n  display: block;\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\n.sidebar a:hover {\n  background-color: #e6e6e6;\n}\n\n.content {\n  margin-left: 250px;\n  flex-grow: 1;\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
