import './modalRegister.css'
import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react';
import axios from 'axios';
import MessageRequest from '../CardMessage/MessageRequest/messageRequest';
import FileUploadArea from '../FileUploadArea/fileUploadArea';
import { registerUserLogs } from '../../api/userLogs';

type ModalRegisterProps = {
    display: boolean, 
    title: string, 
    categories: Array<any>,
    open: any
}

export default function ModalRegister({display, title, categories, open}: ModalRegisterProps) {
    const [active, setActive] = useState<boolean>(false);
    const [activeModal, setActiveModal] = useState<boolean>(true);
    const [inputTitle, setinputTitle] = useState<string>('');
    const [errorTitle, setErrorTitle] = useState<string | null>(null);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
    const [nameFile, setNameFile] = useState<string>('');
    const [fileUpload, setFileUpload] = useState<string | null>(null);

    const handleFileSelect = (file: any) => {
        setNameFile(file.name);
        setFileUpload(file);
    };

    const handleCheckboxChange = (categoryId: number) => {
        if (selectedCategories.includes(categoryId)) {
            setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
        } else {
            setSelectedCategories([...selectedCategories, categoryId]);
        }
    };

    useEffect(() => {
        setActive(display);
    }, [display]);

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setActive(false);
        open(false);
    };

    const handleInputTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setinputTitle(event.target.value);
        setErrorTitle(null);
    }

    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (inputTitle.trim() === '') {
            setErrorTitle('Campo obrigatório.')
            return;
        } else {
            setErrorTitle('')
        }

        if (title === 'NOVA ETAPA') {
            const formData = new FormData();
            if (fileUpload !== null) {
                formData.append('image', fileUpload);
            }
            formData.append('stage', inputTitle);

            axios.post(process.env.REACT_APP_URL_API_AMZ+'api/stages/', formData, 
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then((response) => {
                setActiveModal(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Cadatrado com sucesso.')
                setTimeout(() => {
                    setActive(false);
                    window.location.reload();
                }, 3000);
                registerUserLogs("Cadastrou etapa(s).");
            })
            .catch((error) => {
                setActive(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            });
        } 

        if (title === 'NOVA CATEGORIA') {
            const formData = new FormData();
            if (fileUpload !== null) {
                formData.append('image', fileUpload);
            }
            formData.append('categorie', inputTitle);

            axios.put(process.env.REACT_APP_URL_API_AMZ+'api/categories/', formData, 
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then((response) => {
                setActiveModal(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Cadatrado com sucesso.')
                setTimeout(() => {
                    setActive(false);
                    window.location.reload();
                }, 3000);
                registerUserLogs("Cadastrou categoria(s).");
            })
            .catch((error) => {
                setActive(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            });
        } 

        if (title === 'NOVO SELO') {
            const formData = new FormData();
            if (fileUpload !== null) {
                formData.append('image', fileUpload);
            }
            formData.append('stamp', inputTitle);
            formData.append('categories', JSON.stringify(selectedCategories));

            axios.put(process.env.REACT_APP_URL_API_AMZ+'api/stamps/', formData, 
            {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("access_token")
                }
            })
            .then((response) => {
                setActiveModal(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Sucesso:');
                setMessageRequest('Cadatrado com sucesso.')
                setTimeout(() => {
                    setActive(false);
                    window.location.reload();
                }, 3000);
                registerUserLogs("Cadastrou selo(s).");
            })
            .catch((error) => {
                setActive(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro:');
                setMessageRequest('Cadastro inválido. Revise os dados.')
            });
        } 
        
    }

    const classNameTitle = errorTitle ? 'input-register-stage-error' : 'input-register-stage';

    return(
        <div className={active===true ? 'card-modal-stage' : 'card-modal-stage-none'}>
            <div className='col-message'>
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
            </div>
            <div className={activeModal ? 'modal-stage' : 'card-modal-stage-none'} style={title === 'NOVO SELO' ? {"height": "auto", "marginTop": "50px"} : {"height": "400px"}}>
                <div className='col-title-image-modal' style={title === 'NOVO SELO' ? {"marginTop": "0"} : {"marginTop": "15px"}}>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image"
                    />
                    <h2>{title}</h2>
                </div>
                <div className='register-stage'>
                    <div>
                        <form>
                            <div className='col-register-stage' style={title === 'NOVO SELO' ? {"marginTop": "20px"} : {"marginTop": "0"}}>
                                <div className='row-label' style={{ 'marginTop': '20px' }}>
                                    <label>Logo:</label>
                                    <p className='required-title'>*</p>
                                </div>
                                <FileUploadArea onFileSelect={handleFileSelect} nameFile={nameFile} marginTop={'20px'}/>
                                <div className='row-label'>
                                    <label>Título:</label>
                                    <p className='required-title'>*</p>
                                </div>
                                <input type="text" onChange={handleInputTitle} value={inputTitle} className={classNameTitle} />
                                <span style={{'marginBottom': '20px'}}>{errorTitle}</span>
                                <label style={title === 'NOVO SELO' ? {'display': 'flex'} : {'display': 'none'}}>Categorias Específicas:</label>
                                <div className='col-checkbox'>
                                    <ul>
                                        {categories.map(category => (
                                        <li key={category.id}>
                                            <label style={{"fontSize": '12px', 'display': 'flex', 'alignItems': 'center'}}>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCategories.includes(category.id)}
                                                    onChange={() => handleCheckboxChange(category.id)}
                                                />
                                                {category.categorie}
                                            </label>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='buttons-register-stage'>
                                <button className='button-new-stage' onClick={handleSubmit}>
                                    cadastrar
                                </button>
                                <button className='button-cancel-stage' onClick={handleCancel}>
                                    <u>cancelar</u>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <p className='p-npca' style={{'margin': '0'}}>
                    © NPCA 
                </p>
            </div>
        </div>
    );
}